const productline = localStorage.getItem("productline")
const VITE_APP_NODE_ENV = import.meta.env.VITE_APP_NODE_ENV
const VITE_APP_APIENV = import.meta.env.VITE_APP_APIENV
const customDashboard_NODE_ENV = VITE_APP_NODE_ENV + "_" + VITE_APP_APIENV
let VITE_APP_WALMART_BASE_URL = "https://api.pacvue.com/walmart-newui/api/"
switch (customDashboard_NODE_ENV) {
  case "development_us":
    VITE_APP_WALMART_BASE_URL = "https://api-test.pacvue.com/walmart-newui-dev/api/"
    break
  case "development_cn":
    VITE_APP_WALMART_BASE_URL = "https://api-test.pacvue.cn/walmart-newui-dev/api/"
    break
  case "test_us":
    VITE_APP_WALMART_BASE_URL = "https://api-test.pacvue.com/walmart-newui-dev/api/"
    break
  case "test_cn":
    VITE_APP_WALMART_BASE_URL = "https://api-test.pacvue.cn/walmart-newui-dev/api/"
    break
  case "production_cn":
    VITE_APP_WALMART_BASE_URL = "https://api.pacvue.cn/walmart-newui/api/"
    break
  case "production_eu":
    VITE_APP_WALMART_BASE_URL = "https://api-eu.pacvue.com/walmart-newui/api/"
    break
  case "production_us":
    VITE_APP_WALMART_BASE_URL = "https://api.pacvue.com/walmart-newui/api/"
    break
}

export { VITE_APP_WALMART_BASE_URL }
