import { defineStore } from "pinia"
// service
import { getAWSKnowledgeBaseToken } from "../src/Copilot/api"
// utils
import { initKbStreamingWebsocket } from "../utils/copilot"
// storage
import { forageInstances } from "../src/Copilot/storage"
// constants
import { CONVERSATION_TYPE, SENDER } from "../src/Copilot/constants"
// config
import { indexDBname } from "../src/Copilot/config"

const useCopilotStore = defineStore("copilot", {
  state: () => ({
    // Knowledge Base Start
    kbToken: "",
    kbParam: {},
    kbContent: "",
    contextualConv: []
    // Knowledge Base End
  }),
  actions: {
    async getKbIngredientsAndSave() {
      const res = await getAWSKnowledgeBaseToken()
      this.kbToken = res.token
      this.kbParam = res.param
      this.resetContextualConv()
      if (window.copilotSocket) {
        window.copilotSocket.close()
        window.copilotSocket = null
      }
      initKbStreamingWebsocket() // 开启 Feature Instruction (Knowledge Base) 的 websocket 连接
      // 从 IndexedDB 中读取对话上下文缓存, 初始化 contextualConv
      const forageInstance = forageInstances[CONVERSATION_TYPE.FEATURE_INSTRUCTION]
      const data = await forageInstance.getItem(indexDBname())
      this.contextualConv = data
        .slice(2)
        .slice(-6)
        .map((item) => {
          if (item.sender === SENDER.SELF || (item.sender === SENDER.MODEL && item.format !== "markdown")) {
            return { role: item.sender === SENDER.SELF ? "user" : "AI", content: item.message }
          } else {
            return { role: "AI", content: item.fullMessage }
          }
        })
    },
    resetKbIngredients() {
      this.kbToken = ""
      this.kbParam = {}
      this.kbContent = ""
      if (window.copilotSocket) {
        window.copilotSocket.close()
        window.copilotSocket = null
      }
    },
    setKbContent(content) {
      this.kbContent = content
    },
    resetKbContent() {
      this.kbContent = ""
    },
    appendContextualConv(convInfo) {
      this.contextualConv.push(convInfo)
      this.resetKbContent()
    },
    setContextualConv(conv) {
      this.contextualConv = conv
    },
    resetContextualConv() {
      this.contextualConv = []
    }
  }
})

export default useCopilotStore
