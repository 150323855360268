import { i18n, forageInstance, LOCALE_ALL } from "../src/i18nEntry.js"

let localeCached = false
// 轮询国际化
async function pollLocale() {
  await new Promise((resolve) => {
    const locale = localStorage.getItem("locale") || "EN"
    let interval = null
    const fn = () => {
      const localeMsg = window[`locale_${locale}`]
      if (localeMsg && Object.keys(localeMsg).length > 0) {
        if (interval) {
          clearInterval(interval)
          interval = null
        }
        // i18n.global.locale = locale
        if (Object.keys(i18n.global.getLocaleMessage("pacvue-i18n")).length === 0) {
          i18n.global.setLocaleMessage("pacvue-i18n", localeMsg)
        }
        // 10s 后全量缓存进 IndexDB
        if (!localeCached) {
          localeCached = true
          setTimeout(() => {
            forageInstance.setItem(locale, localeMsg)
            const localeOthers = LOCALE_ALL.filter((item) => item !== locale)
            for (const item of localeOthers) {
              if (window[`locale_${item}`] && Object.keys(window[`locale_${item}`]).length > 0) {
                forageInstance.setItem(item, window[`locale_${item}`])
              }
            }
          }, 10000)
        }
        resolve(true)
      }
    }
    fn()
    interval = setInterval(fn, 50)
    // 超时则使用 IndexDB 中缓存的值, 以防阻塞
    setTimeout(async () => {
      if (interval) {
        clearInterval(interval)
        interval = null
        const localeMsg = await forageInstance.getItem(locale)
        // i18n.global.locale = locale
        // 若缓存中还是没有, 只能无可奈何 fallback 到全 key.
        i18n.global.setLocaleMessage("pacvue-i18n", localeMsg || {})
        resolve(true)
      }
    }, 8000)
  })
}

export { pollLocale }
