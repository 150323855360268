/* eslint-disable no-dupe-keys */
export function useProIndex({
  formatMoneyRoas,
  formatdailyBudget,
  toThousands,
  toThousandsx,
  formatPercent,
  formatMoney,
  formatMoney1,
  formatSpendsMoney,
  formatSalesMoney,
  formatPercentint,
  formatPercent1,
  formatPercent100,
  proIndexConfig
}) {
  /**
   * 格式化指标
   * @param {格式化数据json} totalData
   * @param {字段名称，如果传了只格式化单key的数据} filedName
   * @param {货币符号,$,￥} monenyCode
   * @param {是否需要格式化对比数据,默认flase} showCampar
   * @param {total数据个格式化类型，Product，SellerProduct} totalType
   */
  function getPacvueFormatProIndex(totalData, filedName, showCampar, monenyCode, totalType, from, metric) {
    var { toThousandsxFields, formatPercentFields, fixd2Fields, fixd2NumFields } = proIndexConfig
    var result = {}
    var filedVal = totalData[filedName] || 0
    var filedValPrev = totalData[filedName + "Compar"] || totalData[filedName + "Compare"] || totalData[filedName + "Comapre"] || 0
    var filedValChange = totalData[filedName + "Change"]
    monenyCode = monenyCode || "$"
    if (filedName != undefined) {
      if (filedName == "ROAS" || filedName == "TROAS" || filedName == "TotalROAS" || filedName == "roas" || filedName == "troas") {
        result[filedName] = formatMoneyRoas(filedVal, monenyCode)
        if (showCampar) {
          result[filedName + "Prev"] = formatMoneyRoas(filedValPrev, monenyCode)
          // result[filedName+'Change']=formatPercent(filedValChange);
        }
      } else if (filedName == "dailyBudget") {
        result[filedName] = formatdailyBudget(filedVal, monenyCode)
      } else if (toThousandsxFields.indexOf(filedName) != -1) {
        if (metric) {
          result[filedName] = toThousands(filedVal, monenyCode)
          if (showCampar) {
            result[filedName + "Prev"] = toThousands(filedValPrev, monenyCode)
            // result[filedName+'Change']=formatPercent(filedValChange);
          }
        } else {
          result[filedName] = toThousandsx(filedVal, monenyCode)
          if (showCampar) {
            result[filedName + "Prev"] = toThousandsx(filedValPrev, monenyCode)
            // result[filedName+'Change']=formatPercent(filedValChange);
          }
        }
      } else if (formatPercentFields.indexOf(filedName) != -1) {
        result[filedName] = formatPercent(filedVal)
        if (filedName == "adrate" || filedName == "adRate") {
          result[filedName] = formatPercent1(filedVal)
        }
        if (showCampar) {
          result[filedName + "Prev"] = formatPercent(filedValPrev)
          // result[filedName+'Change']=formatPercent(filedValChange);
        }
      } else if (["CPC", "CPA", "ASP"].indexOf(filedName) != -1) {
        result[filedName] = formatMoney(filedVal, monenyCode) //formatMoneyOrder(filedVal, monenyCode, 1)
        if (showCampar) {
          result[filedName + "Prev"] = formatMoney1(filedValPrev, monenyCode, 1)
          // result[filedName+'Change']=formatPercent(filedValChange);
        }
      } else if (fixd2Fields.indexOf(filedName) != -1) {
        if (metric) {
          result[filedName] = formatMoney1(filedVal, monenyCode)
          if (showCampar) {
            result[filedName + "Prev"] = formatMoney1(filedValPrev, monenyCode)
            // result[filedName+'Change']=formatPercent(filedValChange);
          }
        } else {
          result[filedName] = formatSpendsMoney(filedVal, monenyCode, 1)
          if (showCampar) {
            result[filedName + "Prev"] = formatSpendsMoney(filedValPrev, monenyCode, 1)
            // result[filedName+'Change']=formatPercent(filedValChange);
          }
        }
      } else if (fixd2NumFields.indexOf(filedName) != -1) {
        result[filedName] = toThousands(Number(filedVal || 0).toFixed(2), monenyCode)
        if (showCampar) {
          result[filedName + "Prev"] = toThousands(Number(filedVal || 0).toFixed(2), monenyCode)
          // result[filedName+'Change']=formatPercent(filedValChange);
        }
      } else {
        if (metric) {
          result[filedName] = formatMoney1(filedVal, monenyCode)
          if (showCampar) {
            result[filedName + "Prev"] = formatMoney1(filedValPrev, monenyCode, monenyCode)
          }
        } else {
          result[filedName] = formatSalesMoney(filedVal, monenyCode, 1)
          if (showCampar) {
            result[filedName + "Prev"] = formatSalesMoney(filedValPrev, monenyCode, 1)
          }
        }
      }
      if (showCampar) {
        if (from) {
          filedValChange > 1000 ? (result[filedName + "Change"] = formatPercentint(filedValChange, monenyCode)) : (result[filedName + "Change"] = formatPercent1(filedValChange, monenyCode))
        } else {
          result[filedName + "Change"] = formatPercent(filedValChange, monenyCode)
        }
      }
      return result
    }
    var totalRows = {}
    if (totalType == "Product") {
      totalRows = {
        TotalRevenue: formatSpendsMoney(totalData.TotalRevenue, monenyCode, 1),
        OrganicOfTotal: formatPercent(totalData.OrganicOfTotal, monenyCode),
        AdSalesOfTotal: formatPercent(totalData.AdSalesOfTotal, monenyCode),
        AdSalesSameSkuOfTotal: formatPercent(totalData.AdSalesSameSkuOfTotal, monenyCode),
        Profit: formatSpendsMoney(totalData.Profit, monenyCode, 1),
        Margin: formatPercent(totalData.Margin, monenyCode),
        Spend: formatSpendsMoney(totalData.Spend, monenyCode, 1),
        //AMSRevenue:formatSpendsMoney(totalData.AMSRevenue,monenyCode,1),
        Sales: formatSalesMoney(totalData.Sales, monenyCode, 1),
        AdSales: formatSalesMoney(totalData.AdSales, monenyCode, 1),
        AdSalesSameSku: formatSalesMoney(totalData.AdSalesSameSku, monenyCode, 1),
        AdSalesOtherSku: formatSalesMoney(totalData.AdSalesOtherSku, monenyCode, 1),
        AdSaleUnits: toThousandsx(totalData.AdSaleUnits, monenyCode),
        AdSaleUnitsSameSku: toThousandsx(totalData.AdSaleUnitsSameSku, monenyCode),
        AdOrders: toThousandsx(totalData.AdOrders, monenyCode),
        OrganicRevenue: formatSpendsMoney(totalData.OrganicRevenue, monenyCode, 1),
        SaleUnits: toThousandsx(totalData.SaleUnits, monenyCode),
        AvgSalePrice: formatSpendsMoney(totalData.AvgSalePrice, monenyCode, 1),
        TotalOrders: toThousandsx(totalData.TotalOrders, monenyCode),
        ACOS: formatPercent(totalData.ACOS, monenyCode),
        TACOS: formatPercent(totalData.TACOS, monenyCode),
        ROAS: formatMoneyRoas(totalData.ROAS, monenyCode),
        TROAS: formatMoneyRoas(totalData.TROAS, monenyCode),
        Impression: toThousandsx(totalData.Impression),
        Click: toThousandsx(totalData.Click, monenyCode),
        CTR: formatPercent(totalData.CTR, monenyCode),
        CVR: formatPercent(totalData.CVR, monenyCode),
        clickCvr: formatPercent(totalData.clickCvr, monenyCode),
        clickAcos: formatPercent(totalData.clickAcos, monenyCode),
        clickCpa: formatSpendsMoney(totalData.clickCpa, monenyCode, 1),
        clickRoas: formatMoneyRoas(totalData.clickRoas, monenyCode),
        clickOrders: toThousandsx(totalData.clickOrders, monenyCode),
        clickSaleUnits: toThousandsx(totalData.clickSaleUnits),
        ASP: formatSpendsMoney(totalData.ASP, monenyCode),
        CPC: formatPercent(totalData.CPC, monenyCode),
        GlanceViews: toThousandsx(totalData.GlanceViews, monenyCode),
        ConversionRate: formatPercent(totalData.ConversionRate, monenyCode),

        TotalRevenuePrev: formatSpendsMoney(totalData.TotalRevenueCompare, monenyCode, 1),
        ProfitPrev: formatSpendsMoney(totalData.ProfitCompare, monenyCode, 1),
        MarginPrev: formatPercent(totalData.MarginCompare, monenyCode),
        SpendPrev: formatSpendsMoney(totalData.SpendCompare, monenyCode, 1),
        // AMSRevenuePrev:formatSpendsMoney(totalData.AMSRevenueCompare,monenyCode,1),
        SalesPrev: formatSalesMoney(totalData.SalesCompare, monenyCode, 1),
        AdSalesPrev: formatSalesMoney(totalData.AdSalesCompare, monenyCode, 1),
        AdSalesSameSkuPrev: formatSalesMoney(totalData.AdSalesSameSkuCompare, monenyCode, 1),
        AdSalesOtherSkuPrev: formatSalesMoney(totalData.AdSalesOtherSkuCompare, monenyCode, 1),
        AdSaleUnitsPrev: toThousandsx(totalData.AdSaleUnitsCompare),
        AdSaleUnitsSameSkuPrev: toThousandsx(totalData.AdSaleUnitsSameSkuCompare),
        AdOrdersPrev: toThousandsx(totalData.AdOrdersCompare, monenyCode),
        AdSalesSameSkuOfTotalPrev: formatPercent(totalData.AdSalesSameSkuOfTotalCompare),
        OrganicRevenuePrev: formatSpendsMoney(totalData.OrganicRevenueCompare, monenyCode, 1),
        SaleUnitsPrev: toThousandsx(totalData.SaleUnitsCompare, monenyCode),
        AvgSalePricePrev: formatSpendsMoney(totalData.AvgSalePriceCompare, monenyCode, 1),
        TotalOrdersPrev: toThousandsx(totalData.TotalOrdersCompare, monenyCode),
        ACOSPrev: formatPercent(totalData.ACOSCompare, monenyCode),
        TACOSPrev: formatPercent(totalData.TACOSCompare, monenyCode),
        ROASPrev: formatMoneyRoas(totalData.ROASCompare, monenyCode),
        TROASPrev: formatMoneyRoas(totalData.TROASCompare, monenyCode),
        ImpressionPrev: toThousandsx(totalData.ImpressionCompare),
        ClickPrev: toThousandsx(totalData.ClickCompare, monenyCode),
        CTRPrev: formatPercent(totalData.CTRCompare, monenyCode),
        CVRPrev: formatPercent(totalData.CVRCompare, monenyCode),
        clickCvrPrev: formatPercent(totalData.clickCvrCompare, monenyCode),
        ASPPrev: formatSpendsMoney(totalData.ASPCompare, monenyCode),
        GlanceViewsPrev: toThousandsx(totalData.GlanceViewsCompare),
        ConversionRatePrev: formatPercent(totalData.ConversionRateCompare),

        TotalRevenueChange: formatPercent(totalData.TotalRevenueChange, monenyCode, 1),
        ProfitChange: formatPercent(totalData.ProfitChange, monenyCode, 1),
        MarginChange: formatPercent(totalData.MarginChange, monenyCode, 1),
        SpendChange: formatPercent(totalData.SpendChange, monenyCode, 1),
        // AMSRevenueChange:formatPercent(totalData.AMSRevenueChange,monenyCode,1),
        SalesChange: formatPercent(totalData.SalesChange, monenyCode),
        AdSalesChange: formatPercent(totalData.AdSalesChange, monenyCode, 1),
        AdSalesSameSkuChange: formatPercent(totalData.AdSalesSameSkuChange, monenyCode, 1),
        AdSalesOtherSkuChange: formatPercent(totalData.AdSalesOtherSkuChange, monenyCode, 1),
        AdSaleUnitsChange: formatPercent(totalData.AdSaleUnitsChange, monenyCode),
        AdSaleUnitsSameSkuChange: formatPercent(totalData.AdSaleUnitsSameSkuChange),
        AdOrdersChange: formatPercent(totalData.AdOrdersChange, monenyCode),
        AdSalesSameSkuOfTotalChange: formatPercent(totalData.AdSalesSameSkuOfTotalChange),
        OrganicRevenueChange: formatPercent(totalData.OrganicRevenueChange, monenyCode, 1),
        SaleUnitsChange: formatPercent(totalData.SaleUnitsChange, monenyCode),
        AvgSalePriceChange: formatPercent(totalData.AvgSalePriceChange, monenyCode),
        TotalOrdersChange: formatPercent(totalData.TotalOrdersChange, monenyCode),
        ACOSChange: formatPercent(totalData.ACOSChange, monenyCode),
        TACOSChange: formatPercent(totalData.TACOSChange, monenyCode),
        ROASChange: formatPercent(totalData.ROASChange, monenyCode),
        TROASChange: formatPercent(totalData.TROASChange, monenyCode),
        ImpressionChange: toThousandsx(totalData.ImpressionChange, monenyCode),
        ClickChange: toThousandsx(totalData.ClickChange, monenyCode),
        CTRChange: formatPercent(totalData.CTRChange, monenyCode),
        CVRChange: formatPercent(totalData.CVRChange, monenyCode),
        clickCvrChange: formatPercent(totalData.clickCvrChange, monenyCode),
        ASPChange: formatSpendsMoney(totalData.ASPChange, monenyCode, 1),
        CPCChange: formatPercent(totalData.CPCChange, monenyCode),
        GlanceViewsChange: formatPercent(totalData.GlanceViewsChange, monenyCode),
        ConversionRateChange: formatPercent(totalData.ConversionRateChange, monenyCode)
      }
      return totalRows
    } else if (totalType === "SellerProduct") {
      totalRows = {
        Orders: toThousandsx(totalData.TotalRevenue, monenyCode),
        TotalSales: formatSalesMoney(totalData.TotalSales, monenyCode, 1),
        AvgSalePrice: formatMoney1(totalData.AvgSalePrice, monenyCode),
        AdSpend: formatSpendsMoney(totalData.AdSpend, monenyCode, 1),
        AdSales: formatSalesMoney(totalData.AdSales, monenyCode, 1),
        AdSaleUnits: toThousandsx(totalData.AdSaleUnits, monenyCode),
        Sales: formatSalesMoney(totalData.Sales, monenyCode, 1),
        ACOS: formatPercent(totalData.ACOS, monenyCode),
        TACOS: formatPercent(totalData.TACOS, monenyCode),
        ROAS: formatMoneyRoas(totalData.ROAS, monenyCode),
        TROAS: formatMoneyRoas(totalData.TROAS, monenyCode),
        CTR: formatPercent(totalData.CTR, monenyCode),
        CVR: formatPercent(totalData.CVR, monenyCode),
        clickCvr: formatPercent(totalData.clickCvr, monenyCode),

        clickAcos: formatPercent(totalData.clickAcos, monenyCode),
        clickCpa: formatSpendsMoney(totalData.clickCpa, monenyCode, 1),
        clickRoas: formatMoneyRoas(totalData.clickRoas, monenyCode),
        clickOrders: toThousandsx(totalData.clickOrders, monenyCode),
        clickSaleUnits: toThousandsx(totalData.clickSaleUnits, monenyCode),
        ASP: formatSalesMoney(totalData.ASP, monenyCode, 1),
        CPC: formatPercent(totalData.CPC, monenyCode),
        Clicks: toThousandsx(totalData.Clicks, monenyCode),
        Impression: toThousandsx(totalData.AMS_impressions, monenyCode),
        Units: toThousandsx(totalData.Units, monenyCode),
        SaleUnits: toThousandsx(totalData.Units, monenyCode),
        Avaliable: toThousandsx(totalData.SC_Avaliable, monenyCode),
        Inbound: toThousands(totalData.SC_Inbound, monenyCode),
        Unfulfillable: toThousands(totalData.Unfulfillable, monenyCode),
        Reserved: toThousands(totalData.SC_Reserved, monenyCode),
        spendOfSales: formatPercent(totalData.spendOfSales, monenyCode),
        ReturnRate: formatPercent(totalData.ReturnRate, monenyCode),

        SC_Orders: toThousandsx(totalData.SC_Orders, monenyCode),
        AMS_clicks: toThousandsx(totalData.AMS_clicks, monenyCode),
        SC_OrderedRevenue: formatSalesMoney(totalData.SC_OrderedRevenue, monenyCode, 1),
        AMS_impressions: toThousandsx(totalData.AMS_impressions, monenyCode),
        SC_OrderedUnits: toThousandsx(totalData.SC_OrderedUnits, monenyCode),
        SC_Avaliable: toThousandsx(totalData.SC_Avaliable, monenyCode),
        SC_Inbound: toThousands(totalData.SC_Inbound, monenyCode),
        SC_Reserved: toThousands(totalData.SC_Reserved, monenyCode),
        ASoAS: formatPercent(totalData.ASoAS, monenyCode)
      }
      return totalRows
    }
    totalRows = {
      adSKUOrders: toThousandsx(totalData.adSKUOrders || 0, monenyCode),
      winRate: formatPercent(totalData.winRate, monenyCode),
      validBids: formatSalesMoney(totalData.validBids, monenyCode, 1),

      // sams 1.7版本新增
      adSKUSaleUnits: toThousandsx(totalData.adSKUSaleUnits || 0, monenyCode),
      adSKUSaleUnitsPrev: toThousandsx(totalData.adSKUSaleUnitsCompared || 0, monenyCode),
      adSKUSaleUnitsChange: formatPercent(totalData.adSKUSaleUnitsChange, monenyCode),
      //ic 1.28版本新增
      promotionNtbSales: formatSalesMoney(totalData.promotionNtbSales, monenyCode, 1),
      promotionNtbSalesPrev: formatSalesMoney(totalData.promotionNtbSalesCompared, monenyCode, 1),
      promotionNtbSalesChange: formatPercent(totalData.promotionNtbSalesChange, monenyCode),

      promotionNtbSalesPercent: formatPercent(totalData.promotionNtbSalesPercent, monenyCode),
      promotionNtbSalesPercentPrev: formatPercent(totalData.promotionNtbSalesPercentCompared, monenyCode),
      promotionNtbSalesPercentChange: formatPercent(totalData.promotionNtbSalesPercentChange, monenyCode),
      // promotionSpend:
      marketROAS: formatMoneyRoas(totalData.marketROAS, monenyCode),
      promotionROAS: formatMoneyRoas(totalData.promotionROAS, monenyCode),
      promotionSpend: formatSpendsMoney(totalData.promotionSpend, monenyCode, 1),
      //ic 1.28版本新增
      
      // walmart v1.41新字段start
      totalCpa: formatMoney1(totalData.totalCpa, monenyCode),
      totalCpaPrev: formatMoney1(totalData.totalCpaCompared, monenyCode),
      totalCpaChange: formatPercent(totalData.totalCpaChange, monenyCode),

      totalCPA: formatMoney1(totalData.totalCPA, monenyCode),
      totalCPAPrev: formatMoney1(totalData.totalCPACompared, monenyCode),
      totalCPAChange: formatPercent(totalData.totalCPAChange, monenyCode),

      totalCvr: formatPercent(totalData.totalCvr, monenyCode),
      totalCvrPrev: formatPercent(totalData.totalCvrCompared, monenyCode),
      totalCvrChange: formatPercent(totalData.totalCvrChange, monenyCode),

      totalCVR: formatPercent(totalData.totalCVR, monenyCode),
      totalCVRPrev: formatPercent(totalData.totalCVRCompared, monenyCode),
      totalCVRChange: formatPercent(totalData.totalCVRChange, monenyCode),

      totalAcos: formatPercent(totalData.totalAcos, monenyCode),
      totalAcosPrev: formatPercent(totalData.totalAcosCompared, monenyCode),
      totalAcosChange: formatPercent(totalData.totalAcosChange, monenyCode),

      totalACOS: formatPercent(totalData.totalACOS, monenyCode),
      totalACOSPrev: formatPercent(totalData.totalACOSCompared, monenyCode),
      totalACOSChange: formatPercent(totalData.totalACOSChange, monenyCode),

      totalRoas: formatMoneyRoas(totalData.totalRoas, monenyCode),
      totalRoasPrev: formatMoneyRoas(totalData.totalRoasCompared, monenyCode),
      totalRoasChange: formatPercent(totalData.totalRoasChange, monenyCode),

      totalROAS: formatMoneyRoas(totalData.totalROAS, monenyCode),
      totalROASPrev: formatMoneyRoas(totalData.totalROASCompared, monenyCode),
      totalROASChange: formatPercent(totalData.totalROASChange, monenyCode),

      totalSales: formatSalesMoney(totalData.totalSales, monenyCode, 1),
      totalSalesPrev: formatSalesMoney(totalData.totalSalesCompared, monenyCode, 1),
      totalSalesChange: formatPercent(totalData.totalSalesChange, monenyCode),

      totalOtherSales: formatSalesMoney(totalData.totalOtherSales, monenyCode, 1),
      totalOtherSalesPrev: formatSalesMoney(totalData.totalOtherSalesCompared, monenyCode, 1),
      totalOtherSalesChange: formatPercent(totalData.totalOtherSalesChange, monenyCode),

      totalOtherSalesPercent: formatPercent(totalData.totalOtherSalesPercent, monenyCode),
      totalOtherSalesPercentPrev: formatPercent(totalData.totalOtherSalesPercentCompared, monenyCode),
      totalOtherSalesPercentChange: formatPercent(totalData.totalOtherSalesPercentChange, monenyCode),

      totalOrders: toThousandsx(totalData.totalOrders, monenyCode),
      totalOrdersPrev: toThousandsx(totalData.totalOrdersCompared, monenyCode),
      totalOrdersChange: formatPercent(totalData.totalOrdersChange, monenyCode),

      totalOtherOrders: toThousandsx(totalData.totalOtherOrders || 0, monenyCode),
      totalOtherOrdersPrev: toThousandsx(totalData.totalOtherOrdersCompared || 0, monenyCode),
      totalOtherOrdersChange: formatPercent(totalData.totalOtherOrdersChange || 0, monenyCode),

      totalOtherOrdersPercent: formatPercent(totalData.totalOtherOrdersPercent, monenyCode),
      totalOtherOrdersPercentPrev: formatPercent(totalData.totalOtherOrdersPercentCompared, monenyCode),
      totalOtherOrdersPercentChange: formatPercent(totalData.totalOtherOrdersPercentChange, monenyCode),

      totalSaleUnits: toThousandsx(totalData.totalSaleUnits || 0, monenyCode),
      totalSaleUnitsPrev: toThousandsx(totalData.totalSaleUnitsCompared || 0, monenyCode),
      totalSaleUnitsChange: formatPercent(totalData.totalSaleUnitsChange, monenyCode),

      totalAov: formatMoney(totalData.totalAov, monenyCode),
      totalAovPrev: formatMoney(totalData.totalAovCompared, monenyCode),
      totalAovChange: formatPercent(totalData.totalAovChange, monenyCode),

      totalAOV: formatMoney(totalData.totalAOV, monenyCode),
      totalAOVPrev: formatMoney(totalData.totalAOVCompared, monenyCode),
      totalAOVChange: formatPercent(totalData.totalAOVChange, monenyCode),

      totalAsp: formatMoney(totalData.totalAsp, monenyCode),
      totalAspPrev: formatMoney(totalData.totalAspCompared, monenyCode),
      totalAspChange: formatPercent(totalData.totalAspChange, monenyCode),

      totalASP: formatMoney(totalData.totalASP, monenyCode),
      totalASPPrev: formatMoney(totalData.totalASPCompared, monenyCode),
      totalASPChange: formatPercent(totalData.totalASPChange, monenyCode),

      totalClickSales: formatSalesMoney(totalData.totalClickSales, monenyCode),
      totalClickSalesPrev: formatSalesMoney(totalData.totalClickSalesCompared, monenyCode),
      totalClickSalesChange: formatPercent(totalData.totalClickSalesChange, monenyCode),

      totalSameSKUOrders: toThousandsx(totalData.totalSameSKUOrders || 0, monenyCode),
      totalSameSKUOrdersPrev: toThousandsx(totalData.totalSameSKUOrdersCompared || 0, monenyCode),
      totalSameSKUOrdersChange: formatPercent(totalData.totalSameSKUOrdersChange || 0, monenyCode),

      totalSameSkuSaleUnits: toThousandsx(totalData.totalSameSkuSaleUnits, monenyCode),
      totalSameSkuSaleUnitsPrev: toThousandsx(totalData.totalSameSkuSaleUnitsCompared, monenyCode),
      totalSameSkuSaleUnitsChange: formatPercent(totalData.totalSameSkuSaleUnitsChange, monenyCode),

      onlineOthersaleUnits: toThousandsx(totalData.onlineOthersaleUnits || 0, monenyCode),
      onlineOthersaleUnitsPrev: toThousandsx(totalData.onlineOthersaleUnitsCompared || 0, monenyCode),
      onlineOthersaleUnitsChange: formatPercent(totalData.onlineOthersaleUnitsChange, monenyCode),

      othersaleUnits: toThousandsx(totalData.othersaleUnits || 0, monenyCode),
      othersaleUnitsPrev: toThousandsx(totalData.othersaleUnitsCompared || 0, monenyCode),
      othersaleUnitsChange: formatPercent(totalData.othersaleUnitsChange, monenyCode),

      inStoreAdvertisedOrders: toThousandsx(totalData.inStoreAdvertisedOrders, monenyCode),
      inStoreAdvertisedOrdersPrev: toThousandsx(totalData.inStoreAdvertisedOrdersCompared, monenyCode),
      inStoreAdvertisedOrdersChange: formatPercent(totalData.inStoreAdvertisedOrdersChange, monenyCode),

      inStoreOtherOrders: toThousandsx(totalData.inStoreOtherOrders, monenyCode),
      inStoreOtherOrdersPrev: toThousandsx(totalData.inStoreOtherOrdersCompared, monenyCode),
      inStoreOtherOrdersChange: formatPercent(totalData.inStoreOtherOrdersChange, monenyCode),

      totalSalesPercent: formatPercent(totalData.totalSalesPercent, monenyCode),
      totalSalesPercentPrev: formatPercent(totalData.totalSalesPercentCompared, monenyCode),
      totalSalesPercentChange: formatPercent(totalData.totalSalesPercentChange, monenyCode),

      totalOrdersPercent: formatPercent(totalData.totalOrdersPercent, monenyCode),
      totalOrdersPercentPrev: formatPercent(totalData.totalOrdersPercentCompared, monenyCode),
      totalOrdersPercentChange: formatPercent(totalData.totalOrdersPercentChange, monenyCode),

      totalAdSKUSalesPercent: formatPercent(totalData.totalAdSKUSalesPercent, monenyCode),
      totalAdSKUSalesPercentPrev: formatPercent(totalData.totalAdSKUSalesPercentCompared, monenyCode),
      totalAdSKUSalesPercentChange: formatPercent(totalData.totalAdSKUSalesPercentChange, monenyCode),

      totalAdSKUOrdersPercent: formatPercent(totalData.totalAdSKUOrdersPercent, monenyCode),
      totalAdSKUOrdersPercentPrev: formatPercent(totalData.totalAdSKUOrdersPercentCompared, monenyCode),
      totalAdSKUOrdersPercentChange: formatPercent(totalData.totalAdSKUOrdersPercentChange, monenyCode),

      totalClickROAS: formatMoneyRoas(totalData.totalClickROAS, monenyCode),
      totalClickROASPrev: formatMoneyRoas(totalData.ctotalClickROASCompared, monenyCode),
      totalClickROASChange: formatPercent(totalData.totalClickROASChange, monenyCode),

      totalClickACOS: formatPercent(totalData.totalClickACOS, monenyCode),
      totalClickACOSPrev: formatPercent(totalData.totalClickACOSCompared, monenyCode),
      totalClickACOSChange: formatPercent(totalData.totalClickACOSChange, monenyCode),
      // walmart v1.41新字段end
      sameSKUOrders: toThousandsx(totalData.sameSKUOrders || 0, monenyCode),
      sameSKUOrdersChange: formatPercent(totalData.sameSKUOrdersChange || 0, monenyCode),
      sameSKUOrdersPrev: toThousandsx(totalData.sameSKUOrdersCompared || 0, monenyCode),

      adSKUSales: formatSalesMoney(totalData.adSKUSales, monenyCode),
      adSKUSalesChange: formatPercent(totalData.adSKUSalesChange, monenyCode),
      adSKUSalesPrev: formatSalesMoney(totalData.adSKUSalesCompared, monenyCode),

      inStoreAttributedSales: formatSalesMoney(totalData.inStoreAttributedSales, monenyCode),
      inStoreAttributedSalesChange: formatPercent(totalData.inStoreAttributedSalesChange, monenyCode),
      inStoreAttributedSalesPrev: formatSalesMoney(totalData.inStoreAttributedSalesCompared, monenyCode),

      inStoreOrders: toThousandsx(totalData.inStoreOrders || 0, monenyCode),
      inStoreOrdersChange: formatPercent(totalData.inStoreOrdersChange || 0, monenyCode),
      inStoreOrdersPrev: toThousandsx(totalData.inStoreOrdersCompared || 0, monenyCode),

      inStoreUnitsSold: toThousandsx(totalData.inStoreUnitsSold || 0, monenyCode),
      inStoreUnitsSoldChange: formatPercent(totalData.inStoreUnitsSoldChange || 0, monenyCode),
      inStoreUnitsSoldPrev: toThousandsx(totalData.inStoreUnitsSoldCompared || 0, monenyCode),

      storeSales: toThousandsx(totalData.storeSales || 0, monenyCode),
      storeSalesChange: formatPercent(totalData.storeSalesChange || 0, monenyCode),
      storeSalesPrev: toThousandsx(totalData.storeSalesCompared || 0, monenyCode),

      storeOrders: toThousandsx(totalData.storeOrders || 0, monenyCode),
      storeOrdersChange: formatPercent(totalData.storeOrdersChange || 0, monenyCode),
      storeOrdersPrev: toThousandsx(totalData.storeOrdersCompared || 0, monenyCode),

      otherSalesPercent: formatPercent(totalData.OtherSales, monenyCode),
      otherSalesPercentChange: formatPercent(totalData.otherSalesPercentChange, monenyCode),
      otherSalesPercentPrev: formatPercent(totalData.otherSalesPercentChange, monenyCode),

      storeAdvertisedSkuOrders: toThousandsx(totalData.storeAdvertisedSkuOrders || 0, monenyCode),
      storeAdvertisedSkuOrdersChange: formatPercent(totalData.storeAdvertisedSkuOrdersChange || 0, monenyCode),
      storeAdvertisedSkuOrdersPrev: toThousandsx(totalData.storeAdvertisedSkuOrdersCompared || 0, monenyCode),

      inStoreOtherSales: formatSalesMoney(totalData.inStoreOtherSales, monenyCode),
      inStoreOtherSalesChange: formatSalesMoney(totalData.inStoreOtherSalesChange, monenyCode),
      inStoreOtherSalesPrev: formatSalesMoney(totalData.inStoreOtherSalesCompared, monenyCode),

      storeOtherOrders: toThousandsx(totalData.storeOtherOrders || 0, monenyCode),
      storeOtherOrdersChange: formatPercent(totalData.storeOtherOrdersChange || 0, monenyCode),
      storeOtherOrdersPrev: toThousandsx(totalData.storeOtherOrdersCompared || 0, monenyCode),

      redemptionsUsed: toThousandsx(totalData.redemptionsUsed, monenyCode),
      redemptionsUsedChange: formatPercent(totalData.redemptionsUsedChange, monenyCode),
      redemptionsUsedPrev: formatPercent(totalData.redemptionsUsedCompared, monenyCode),
      Sales: formatSalesMoney(totalData.Sales, monenyCode, 1),
      promotionSales: formatSalesMoney(totalData.promotionSales, monenyCode, 1),
      promotionSalesChange: formatPercent(totalData.promotionSalesChange, monenyCode),
      promotionSalesPrev: formatPercent(totalData.promotionSalesCompared, monenyCode),
      ntbPromotionSales: formatSalesMoney(totalData.ntbPromotionSales, monenyCode, 1),
      ntbPromotionSalesChange: formatPercent(totalData.ntbPromotionSalesChange, monenyCode),
      ntbPromotionSalesPrev: formatPercent(totalData.ntbPromotionSalesCompared, monenyCode),
      percentNTBPromotionSales: formatPercent(totalData.percentNTBPromotionSales, monenyCode),
      percentNTBPromotionSalesChange: formatPercent(totalData.percentNTBPromotionSalesChange, monenyCode),
      percentNTBPromotionSalesPrev: formatPercent(totalData.percentNTBPromotionSalesCompared, monenyCode),
      SameAsinSales: formatSalesMoney(totalData.SameAsinSales, monenyCode, 1),
      PromotedSales: formatSalesMoney(totalData.PromotedSales, monenyCode, 1),
      CampaignSpend: formatSpendsMoney(totalData.CampaignSpend, monenyCode, 1),
      Spend: formatSpendsMoney(totalData.Spend, monenyCode, 1),
      Impression: toThousandsx(totalData.Impression, monenyCode),
      Click: toThousandsx(totalData.Click, monenyCode),
      CTR: formatPercent(totalData.CTR, monenyCode),
      CPC: formatMoney1(totalData.CPC, monenyCode),
      Conversion: toThousandsx(totalData.Conversion),
      SaleUnits: toThousandsx(totalData.SaleUnits),
      CPA: formatMoney1(totalData.CPA, monenyCode),
      CVR: formatPercent(totalData.CVR, monenyCode),
      clickCvr: formatPercent(totalData.clickCvr),

      clickAcos: formatPercent(totalData.clickAcos, monenyCode),
      clickCpa: formatSpendsMoney(totalData.clickCpa, monenyCode, 1),
      clickRoas: formatMoneyRoas(totalData.clickRoas, monenyCode),
      clickOrders: toThousandsx(totalData.clickOrders, monenyCode),
      clickSaleUnits: toThousandsx(totalData.clickSaleUnits, monenyCode),
      ASP: formatMoney1(totalData.ASP, monenyCode),
      ACOS: formatPercent(totalData.ACOS, monenyCode),
      ROAS: formatMoneyRoas(totalData.ROAS, monenyCode),
      OtherSalesPercent: formatPercent(totalData.OtherSalesPercent, monenyCode),
      OrderPrice: formatMoney1(totalData.OrderPrice, monenyCode),
      OtherSales: formatSalesMoney(totalData.OtherSales, monenyCode, 1),
      dailyBudget: formatdailyBudget(totalData.dailyBudget, monenyCode),
      //dsp
      TotalCost: formatSalesMoney(totalData.TotalCost, monenyCode, 1),
      Impressions: toThousandsx(totalData.Impressions, monenyCode),
      eCPM: formatMoney1(totalData.eCPM, monenyCode),
      ClickThroughs: toThousandsx(totalData.ClickThroughs, monenyCode),
      DPV: toThousandsx(totalData.DPV, monenyCode),
      DPVR: formatPercent(totalData.DPVR, monenyCode),
      eCPDPV: formatMoney1(totalData.eCPDPV, monenyCode),
      ATC: toThousandsx(totalData.ATC, monenyCode),
      ATCR: formatPercent(totalData.ATCR, monenyCode),
      eCPATC: formatMoney1(totalData.eCPATC, monenyCode),
      Purchases: toThousandsx(totalData.Purchases, monenyCode),
      PurchaseRate: formatPercent(totalData.PurchaseRate, monenyCode),
      eCPP: formatMoney1(totalData.eCPP, monenyCode, monenyCode),
      UnitsSold: toThousandsx(totalData.UnitsSold, monenyCode),
      ConversionRate: formatPercent(totalData.ConversionRate, monenyCode),
      SalesUSD: formatSalesMoney(totalData.SalesUSD, monenyCode, 1),
      TotalROAS: formatMoneyRoas(totalData.TotalROAS, monenyCode),
      NewToBrandPurchases: toThousandsx(totalData.NewToBrandPurchases, monenyCode),
      PercentOfPurchasesNewToBrand: formatPercent(totalData.PercentOfPurchasesNewToBrand, monenyCode),
      eCPC: formatMoney1(totalData.eCPC, monenyCode),

      SalesPrev: formatSalesMoney(totalData.SalesCompar || totalData.SalesCompare || 0, monenyCode, 1),
      SameAsinSalesPrev: formatSalesMoney(totalData.SameAsinSalesCompar, monenyCode, 1),
      PromotedSalesPrev: formatSalesMoney(totalData.PromotedSalesCompar, monenyCode, 1),
      CampaignSpendPrev: formatSpendsMoney(totalData.CampaignSpendCompar || totalData.CampaignSpendCompar || 0, monenyCode, 1),
      SpendPrev: formatSpendsMoney(totalData.SpendCompar || totalData.SpendCompare || 0, monenyCode, 1),
      ImpressionPrev: toThousandsx(totalData.ImpressionCompar || totalData.ImpressionCompare || 0),
      ClickPrev: toThousandsx(totalData.ClickCompar || totalData.ClickCompare || 0, monenyCode),
      CTRPrev: formatPercent(totalData.CTRCompar || totalData.CTRCompare || 0, monenyCode),
      CPCPrev: formatMoney1(totalData.CPCCompar || totalData.CPCCompare || 0, monenyCode),
      ConversionPrev: toThousandsx(totalData.ConversionCompar || totalData.ConversionCompare || 0),
      SaleUnitsPrev: toThousandsx(totalData.SaleUnitsCompar || totalData.SaleUnitsCompare || 0, monenyCode),
      CPAPrev: formatMoney1(totalData.CPACompar || totalData.CPACompare || 0, monenyCode),
      CVRPrev: formatPercent(totalData.CVRCompar || totalData.CVRCompare || 0, monenyCode),
      clickCvrPrev: formatPercent(totalData.clickCvrCompar || totalData.clickCvrCompare || 0, monenyCode),

      ASPPrev: formatMoney1(totalData.ASPCompar || totalData.ASPCompare || 0, monenyCode),
      ACOSPrev: formatPercent(totalData.ACOSCompar || totalData.ACOSCompare || 0, monenyCode),

      ROASPrev: formatMoneyRoas(totalData.ROASCompar || totalData.ROASCompare || 0, monenyCode),
      OtherSalesPercentPrev: formatPercent(totalData.OtherSalesPercentCompar, monenyCode),
      OrderPricePrev: formatMoney1(totalData.OrderPriceCompar, monenyCode),
      OtherSalesPrev: formatSalesMoney(totalData.OtherSalesCompar, monenyCode, 1),

      SalesChange: formatPercent(totalData.SalesChange, monenyCode),
      SameAsinSalesChange: formatPercent(totalData.SameAsinSalesChange, monenyCode),
      PromotedSalesChange: formatPercent(totalData.PromotedSalesChange, monenyCode),
      CampaignSpendChange: formatPercent(totalData.CampaignSpendChange, monenyCode),
      SpendChange: formatPercent(totalData.SpendChange, monenyCode),
      ImpressionChange: formatPercent(totalData.ImpressionChange, monenyCode),
      ClickChange: formatPercent(totalData.ClickChange, monenyCode),
      CTRChange: formatPercent(totalData.CTRChange, monenyCode),
      CPCChange: formatPercent(totalData.CPCChange, monenyCode),
      ConversionChange: formatPercent(totalData.ConversionChange, monenyCode),
      SaleUnitsChange: formatPercent(totalData.SaleUnitsChange, monenyCode),
      CPAChange: formatPercent(totalData.CPAChange, monenyCode),
      CVRChange: formatPercent(totalData.CVRChange, monenyCode),
      clickCvrChange: formatPercent(totalData.clickCvrChange, monenyCode),
      ASPChange: formatPercent(totalData.ASPChange, monenyCode),
      ACOSChange: formatPercent(totalData.ACOSChange, monenyCode),

      ROASChange: formatPercent(totalData.ROASChange, monenyCode),

      OtherSalesPercentChange: formatPercent(totalData.OtherSalesPercentChange, monenyCode),
      OrderPriceChange: formatMoney1(totalData.OrderPriceChange, monenyCode, monenyCode),
      OtherSalesChange: formatSalesMoney(totalData.OtherSalesChange, monenyCode, 1),

      NewToBrandOrderRate: formatPercent(totalData.NewToBrandOrderRate, monenyCode),
      NewToBrandOrders: toThousandsx(totalData.NewToBrandOrders, monenyCode),
      NewToBrandOrdersPercentage: formatPercent(totalData.NewToBrandOrdersPercentage, monenyCode),
      NewToBrandSales: formatSalesMoney(totalData.NewToBrandSales, monenyCode, 1),
      NewToBrandSalesPercentage: formatPercent(totalData.NewToBrandSalesPercentage, monenyCode),
      NewToBrandUnitsOrdered: toThousandsx(totalData.NewToBrandUnitsOrdered, monenyCode),
      NewToBrandUnitsOrderedPercentage: formatPercent(totalData.NewToBrandUnitsOrderedPercentage, monenyCode),
      KindleEditionNormalizedPagesRead: totalData.KindleEditionNormalizedPagesRead,
      KindleEditionNormalizedPagesRoyalties: formatPercent(totalData.KindleEditionNormalizedPagesRoyalties, monenyCode),

      NewToBrandOrderRatePrev: formatPercent(totalData.NewToBrandOrderRateCompar, monenyCode),
      NewToBrandOrdersPrev: toThousandsx(totalData.NewToBrandOrdersCompar, monenyCode),
      NewToBrandOrdersPercentagePrev: formatPercent(totalData.NewToBrandOrdersPercentageCompar, monenyCode),
      NewToBrandSalesPrev: formatSalesMoney(totalData.NewToBrandSalesCompar, monenyCode, 1),
      NewToBrandSalesPercentagePrev: formatPercent(totalData.NewToBrandSalesPercentageCompar, monenyCode),
      NewToBrandUnitsOrderedPrev: formatPercent(totalData.NewToBrandUnitsOrderedCompar, monenyCode),
      NewToBrandUnitsOrderedPercentagePrev: formatPercent(totalData.NewToBrandUnitsOrderedPercentageCompar, monenyCode),
      KindleEditionNormalizedPagesReadPrev: totalData.KindleEditionNormalizedPagesReadCompar,
      KindleEditionNormalizedPagesRoyaltiesPrev: formatPercent(totalData.KindleEditionNormalizedPagesRoyaltiesCompar, monenyCode),

      NewToBrandOrderRateChange: formatPercent(totalData.NewToBrandOrderRateChange, monenyCode),
      NewToBrandOrdersChange: toThousandsx(totalData.NewToBrandOrdersChange, monenyCode),
      NewToBrandOrdersPercentageChange: formatPercent(totalData.NewToBrandOrdersPercentageChange, monenyCode),
      NewToBrandSalesChange: formatPercent(totalData.NewToBrandSalesChange, monenyCode, 1),
      NewToBrandSalesPercentageChange: formatPercent(totalData.NewToBrandSalesPercentageChange, monenyCode),
      NewToBrandUnitsOrderedChange: formatPercent(totalData.NewToBrandUnitsOrderedChange, monenyCode),
      NewToBrandUnitsOrderedPercentageChange: formatPercent(totalData.NewToBrandUnitsOrderedPercentageChange, monenyCode),
      KindleEditionNormalizedPagesReadChange: totalData.KindleEditionNormalizedPagesReadChange,
      KindleEditionNormalizedPagesRoyaltiesChange: formatPercent(totalData.KindleEditionNormalizedPagesRoyaltiesChange, monenyCode),

      //kroger新增
      clickCPA: formatSpendsMoney(totalData.clickCPA, monenyCode, 1),
      clickCPAPrev: formatSpendsMoney(totalData.clickCPACompared, monenyCode),
      clickCPAChange: formatPercent(totalData.clickCPAChange, monenyCode),

      clickCVR: formatPercent(totalData.clickCVR, monenyCode),
      clickCVRPrev: formatPercent(totalData.clickCVRCompared, monenyCode),
      clickCVRChange: formatPercent(totalData.clickCVRChange, monenyCode),

      //online,offline
      onlineSales: formatSalesMoney(totalData.onlineSales, monenyCode),
      onlineSalesPrev: formatSalesMoney(totalData.onlineSalesCompared, monenyCode),
      onlineSalesChange: formatPercent(totalData.onlineSalesChange, monenyCode),

      deliveryOnlineSales: formatSalesMoney(totalData.deliveryOnlineSales, monenyCode),
      deliveryOnlineSalesPrev: formatSalesMoney(totalData.deliveryOnlineSalesCompared, monenyCode),
      deliveryOnlineSalesChange: formatPercent(totalData.deliveryOnlineSalesChange, monenyCode),

      pickupOnlineSales: formatSalesMoney(totalData.deliveryOnlineSales, monenyCode),
      pickupOnlineSalesPrev: formatSalesMoney(totalData.deliveryOnlineSalesCompared, monenyCode),
      pickupOnlineSalesChange: formatPercent(totalData.deliveryOnlineSalesChange, monenyCode),

      //criteov2.4.4新增onlineroas,onlineacos,onlineclickroas,onlineclickacos
      onlineRoas: formatMoneyRoas(totalData.onlineRoas || 0, monenyCode),
      onlineRoasPrev: formatMoneyRoas(totalData.onlineRoasCompared || 0, monenyCode),
      onlineRoasChange: formatPercent(totalData.onlineRoasChange || 0, monenyCode),

      onlineAcos: formatPercent(totalData.onlineAcos || 0, monenyCode),
      onlineAcosPrev: formatPercent(totalData.onlineAcosCompared || 0, monenyCode),
      onlineAcosChange: formatPercent(totalData.onlineAcosChange || 0, monenyCode),

      onlineClickRoas: formatMoneyRoas(totalData.onlineClickRoas || 0, monenyCode),
      onlineClickRoasPrev: formatMoneyRoas(totalData.onlineClickRoasCompared || 0, monenyCode),
      onlineClickRoasChange: formatPercent(totalData.onlineClickRoasChange || 0, monenyCode),

      onlineClickAcos: formatPercent(totalData.onlineClickAcos || 0, monenyCode),
      onlineClickAcosPrev: formatPercent(totalData.onlineClickAcosCompared || 0, monenyCode),
      onlineClickAcosChange: formatPercent(totalData.onlineClickAcosChange || 0, monenyCode),

      onlineOrders: toThousandsx(totalData.onlineOrders || 0, monenyCode),
      onlineOrdersPrev: toThousandsx(totalData.onlineOrdersCompared || 0, monenyCode),
      onlineOrdersChange: formatPercent(totalData.onlineOrdersChange || 0, monenyCode),

      //criteov2.4.6新增assistedSales,assistedSaleUnits
      assistedSales: formatSalesMoney(totalData.assistedSales, monenyCode),
      assistedSalesPrev: formatSalesMoney(totalData.assistedSalesCompared, monenyCode),
      assistedSalesChange: formatPercent(totalData.assistedSalesChange, monenyCode),
          
      clickAssistedSales: formatSalesMoney(totalData.clickAssistedSales, monenyCode),
      clickAssistedSalesPrev: formatSalesMoney(totalData.clickAssistedSalesCompared, monenyCode),
      clickAssistedSalesChange: formatPercent(totalData.clickAssistedSalesChange, monenyCode),
          
      assistedSaleUnits: toThousandsx(totalData.assistedSaleUnits || 0, monenyCode),
      assistedSaleUnitsPrev: toThousandsx(totalData.assistedSaleUnitsCompared || 0, monenyCode),
      assistedSaleUnitsChange: formatPercent(totalData.assistedSaleUnitsChange || 0, monenyCode),
          
      clickAssistedSaleUnits: toThousandsx(totalData.clickAssistedSaleUnits || 0, monenyCode),
      clickAssistedSaleUnitsPrev: toThousandsx(totalData.clickAssistedSaleUnitsCompared || 0, monenyCode),
      clickAssistedSaleUnitsChange: formatPercent(totalData.clickAssistedSaleUnitsChange || 0, monenyCode),

      deliveryOnlineOrders: toThousandsx(totalData.deliveryOnlineOrders || 0, monenyCode),
      deliveryOnlineOrdersPrev: toThousandsx(totalData.deliveryOnlineOrdersCompared || 0, monenyCode),
      deliveryOnlineOrdersChange: formatPercent(totalData.deliveryOnlineOrdersChange || 0, monenyCode),

      pickupOnlineOrders: toThousandsx(totalData.pickupOnlineOrders || 0, monenyCode),
      pickupOnlineOrdersPrev: toThousandsx(totalData.pickupOnlineOrdersCompared || 0, monenyCode),
      pickupOnlineOrdersChange: formatPercent(totalData.pickupOnlineOrdersChange || 0, monenyCode),

      onlineAdvertisedSkuSales: formatSalesMoney(totalData.onlineAdvertisedSkuSales, monenyCode),
      onlineAdvertisedSkuSalesPrev: formatSalesMoney(totalData.onlineAdvertisedSkuSalesCompared, monenyCode),
      onlineAdvertisedSkuSalesChange: formatPercent(totalData.onlineAdvertisedSkuSalesChange, monenyCode),

      deliveryOnlineAdvertisedSkuSales: formatSalesMoney(totalData.deliveryOnlineAdvertisedSkuSales, monenyCode),
      deliveryOnlineAdvertisedSkuSalesPrev: formatSalesMoney(totalData.deliveryOnlineAdvertisedSkuSalesCompared, monenyCode),
      deliveryOnlineAdvertisedSkuSalesChange: formatPercent(totalData.deliveryOnlineAdvertisedSkuSalesChange, monenyCode),

      pickupOnlineAdvertisedSkuSales: formatSalesMoney(totalData.pickupOnlineAdvertisedSkuSales, monenyCode),
      pickupOnlineAdvertisedSkuSalesPrev: formatSalesMoney(totalData.pickupOnlineAdvertisedSkuSalesCompared, monenyCode),
      pickupOnlineAdvertisedSkuSalesChange: formatPercent(totalData.pickupOnlineAdvertisedSkuSalesChange, monenyCode),

      onlineAdvertisedSkuOrders: toThousandsx(totalData.onlineAdvertisedSkuOrders || 0, monenyCode),
      onlineAdvertisedSkuOrdersPrev: toThousandsx(totalData.onlineAdvertisedSkuOrdersCompared || 0, monenyCode),
      onlineAdvertisedSkuOrdersChange: formatPercent(totalData.onlineAdvertisedSkuOrdersChange || 0, monenyCode),

      deliveryOnlineAdvertisedSkuOrders: toThousandsx(totalData.deliveryOnlineAdvertisedSkuOrders || 0, monenyCode),
      deliveryOnlineAdvertisedSkuOrdersPrev: toThousandsx(totalData.deliveryOnlineAdvertisedSkuOrdersCompared || 0, monenyCode),
      deliveryOnlineAdvertisedSkuOrdersChange: formatPercent(totalData.deliveryOnlineAdvertisedSkuOrdersChange || 0, monenyCode),

      pickupOnlineAdvertisedSkuOrders: toThousandsx(totalData.pickupOnlineAdvertisedSkuOrders || 0, monenyCode),
      pickupOnlineAdvertisedSkuOrdersPrev: toThousandsx(totalData.pickupOnlineAdvertisedSkuOrdersCompared || 0, monenyCode),
      pickupOnlineAdvertisedSkuOrdersChange: formatPercent(totalData.pickupOnlineAdvertisedSkuOrdersChange || 0, monenyCode),

      onlineOtherSales: formatSalesMoney(totalData.onlineOtherSales, monenyCode),
      onlineOtherSalesPrev: formatSalesMoney(totalData.onlineOtherSalesCompared, monenyCode),
      onlineOtherSalesChange: formatPercent(totalData.onlineOtherSalesChange, monenyCode),

      deliveryOnlineOtherSales: formatSalesMoney(totalData.deliveryOnlineOtherSales, monenyCode),
      deliveryOnlineOtherSalesPrev: formatSalesMoney(totalData.deliveryOnlineOtherSalesCompared, monenyCode),
      deliveryOnlineOtherSalesChange: formatPercent(totalData.deliveryOnlineOtherSalesChange, monenyCode),

      pickupOnlineOtherSales: formatSalesMoney(totalData.pickupOnlineOtherSales, monenyCode),
      pickupOnlineOtherSalesPrev: formatSalesMoney(totalData.pickupOnlineOtherSalesCompared, monenyCode),
      pickupOnlineOtherSalesChange: formatPercent(totalData.pickupOnlineOtherSalesChange, monenyCode),

      otherOrders: toThousandsx(totalData.otherOrders || 0, monenyCode),
      otherOrdersPrev: toThousandsx(totalData.otherOrdersCompared || 0, monenyCode),
      otherOrdersChange: formatPercent(totalData.otherOrdersChange || 0, monenyCode),

      onlineOtherOrders: toThousandsx(totalData.onlineOtherOrders || 0, monenyCode),
      onlineOtherOrdersPrev: toThousandsx(totalData.onlineOtherOrdersCompared || 0, monenyCode),
      onlineOtherOrdersChange: formatPercent(totalData.onlineOtherOrdersChange || 0, monenyCode),

      deliveryOnlineOtherOrders: toThousandsx(totalData.deliveryOnlineOtherOrders || 0, monenyCode),
      deliveryOnlineOtherOrdersPrev: toThousandsx(totalData.deliveryOnlineOtherOrdersCompared || 0, monenyCode),
      deliveryOnlineOtherOrdersChange: formatPercent(totalData.deliveryOnlineOtherOrdersChange || 0, monenyCode),

      pickupOnlineOtherOrders: toThousandsx(totalData.pickupOnlineOtherOrders || 0, monenyCode),
      pickupOnlineOtherOrdersPrev: toThousandsx(totalData.pickupOnlineOtherOrdersCompared || 0, monenyCode),
      pickupOnlineOtherOrdersChange: formatPercent(totalData.pickupOnlineOtherOrdersChange || 0, monenyCode),

      buyers: toThousandsx(totalData.buyers || 0, monenyCode),
      buyersPrev: toThousandsx(totalData.buyersCompared || 0, monenyCode),
      buyersChange: formatPercent(totalData.buyersChange || 0, monenyCode),

      totalBuyers: toThousandsx(totalData.totalBuyers || 0, monenyCode),
      totalBuyersPrev: toThousandsx(totalData.totalBuyersCompared || 0, monenyCode),
      totalBuyersChange: formatPercent(totalData.totalBuyersChange || 0, monenyCode),

      onlineBuyers: toThousandsx(totalData.onlineBuyers || 0, monenyCode),
      onlineBuyersPrev: toThousandsx(totalData.onlineBuyersCompared || 0, monenyCode),
      onlineBuyersChange: formatPercent(totalData.onlineBuyersChange || 0, monenyCode),

      deliveryOnlineBuyers: toThousandsx(totalData.deliveryOnlineBuyers || 0, monenyCode),
      deliveryOnlineBuyersPrev: toThousandsx(totalData.deliveryOnlineBuyersCompared || 0, monenyCode),
      deliveryOnlineBuyersChange: formatPercent(totalData.deliveryOnlineBuyersChange || 0, monenyCode),

      pickupOnlineBuyers: toThousandsx(totalData.pickupOnlineBuyers || 0, monenyCode),
      pickupOnlineBuyersPrev: toThousandsx(totalData.pickupOnlineBuyersCompared || 0, monenyCode),
      pickupOnlineBuyersChange: formatPercent(totalData.pickupOnlineBuyersChange || 0, monenyCode),

      storeBuyers: toThousandsx(totalData.storeBuyers || 0, monenyCode),
      storeBuyersPrev: toThousandsx(totalData.storeBuyersCompared || 0, monenyCode),
      storeBuyersChange: formatPercent(totalData.storeBuyersChange || 0, monenyCode),

      onlineClickSales: formatSalesMoney(totalData.onlineClickSales, monenyCode),
      onlineClickSalesPrev: formatSalesMoney(totalData.onlineClickSalesCompared, monenyCode),
      onlineClickSalesChange: formatPercent(totalData.onlineClickSalesChange, monenyCode),
      onlineSalesPer: formatPercent(totalData.onlineSalesPer, monenyCode),
      onlineSalesPerPrev: formatPercent(totalData.onlineSalesPerCompared, monenyCode),
      onlineSalesPerChange: formatPercent(totalData.onlineSalesPerChange, monenyCode),
      onlineClickSalesPer: formatPercent(totalData.onlineClickSalesPer, monenyCode),
      onlineClickSalesPerPrev: formatPercent(totalData.onlineClickSalesPerCompared, monenyCode),
      onlineClickSalesPerChange: formatPercent(totalData.onlineClickSalesPerChange, monenyCode),
      offlineSales: formatSalesMoney(totalData.offlineSales, monenyCode),
      offlineSalesPrev: formatSalesMoney(totalData.offlineSalesCompared, monenyCode),
      offlineSalesChange: formatPercent(totalData.offlineSalesChange, monenyCode),
      offlineClickSales: formatSalesMoney(totalData.offlineClickSales, monenyCode),
      offlineClickSalesPrev: formatSalesMoney(totalData.offlineClickSalesCompared, monenyCode),
      offlineClickSalesChange: formatPercent(totalData.offlineClickSalesChange, monenyCode),
      offlineOrders: toThousandsx(totalData.offlineOrders || 0, monenyCode),
      offlineOrdersPrev: toThousandsx(totalData.offlineOrdersCompared || 0, monenyCode),
      offlineOrdersChange: formatPercent(totalData.offlineOrdersChange || 0, monenyCode),
      offlineClickOrders: toThousandsx(totalData.offlineClickOrders || 0, monenyCode),
      offlineClickOrdersPrev: toThousandsx(totalData.offlineClickOrdersCompared || 0, monenyCode),
      offlineClickOrdersChange: formatPercent(totalData.offlineClickOrdersChange || 0, monenyCode),
      offlineSaleUnits: toThousandsx(totalData.offlineSaleUnits || 0, monenyCode),
      offlineSaleUnitsPrev: toThousandsx(totalData.offlineSaleUnitsCompared || 0, monenyCode),
      offlineSaleUnitsChange: formatPercent(totalData.offlineSaleUnitsChange || 0, monenyCode),
      offlineClickSaleUnits: toThousandsx(totalData.offlineClickSaleUnits || 0, monenyCode),
      offlineClickSaleUnitsPrev: toThousandsx(totalData.offlineClickSaleUnitsCompared || 0, monenyCode),
      offlineClickSaleUnitsChange: formatPercent(totalData.offlineClickSaleUnitsChange || 0, monenyCode),

      //HourlyTrend新增
      avg_impressions: toThousands(Number(totalData.avg_impressions || 0).toFixed(2), monenyCode),
      avg_clicks: toThousands(Number(totalData.avg_clicks || 0).toFixed(2), monenyCode),
      avg_cost: formatMoney1(totalData.avg_cost, monenyCode, 2),
      avg_attributed_conversion: toThousands(Number(totalData.avg_attributed_conversion || 0).toFixed(2), monenyCode),
      avg_attributed_units_ordered: toThousands(Number(totalData.avg_attributed_units_ordered || 0).toFixed(2), monenyCode),
      avg_attributed_sales: formatMoney1(totalData.avg_attributed_sales, monenyCode, 2),

      //兼容其他平台的指标
      spend: formatSpendsMoney(totalData.spend || 0, monenyCode, 1),
      spendPrev: formatSpendsMoney(totalData.spendCompared || 0, monenyCode, 1),
      spendChange: formatPercent(totalData.spendChange, monenyCode),

      impression: toThousandsx(totalData.impression || 0, monenyCode),
      impressionPrev: toThousandsx(totalData.impressionCompared || 0, monenyCode),
      impressionChange: formatPercent(totalData.impressionChange, monenyCode),

      impressions: toThousandsx(totalData.impressions, monenyCode),
      impressionsPrev: toThousandsx(totalData.impressionsCompared, monenyCode),
      impressionsChange: formatPercent(totalData.impressionsChange, monenyCode),

      orders: toThousandsx(totalData.orders, monenyCode),
      ordersPrev: toThousandsx(totalData.ordersCompared, monenyCode),
      ordersChange: formatPercent(totalData.ordersChange, monenyCode),

      click: toThousandsx(totalData.click || 0, monenyCode),
      clickPrev: toThousandsx(totalData.clickCompared || 0, monenyCode),
      clickChange: formatPercent(totalData.clickChange, monenyCode),

      clicks: toThousandsx(totalData.clicks, monenyCode),
      clicksPrev: toThousandsx(totalData.clicksCompared, monenyCode),
      clicksChange: formatPercent(totalData.clicksChange, monenyCode),

      ctr: formatPercent(totalData.ctr, monenyCode),
      ctrPrev: formatPercent(totalData.ctrCompared, monenyCode),
      ctrChange: formatPercent(totalData.ctrChange, monenyCode),

      cpc: formatMoney1(totalData.cpc, monenyCode, monenyCode),
      cpcPrev: formatMoney1(totalData.cpcCompared, monenyCode),
      cpcChange: formatPercent(totalData.cpcChange, monenyCode),

      conversions: toThousandsx(totalData.conversions, monenyCode),
      conversionsPrev: toThousandsx(totalData.conversionsCompared, monenyCode),
      conversionsChange: formatPercent(totalData.conversionsChange, monenyCode),

      saleUnits: toThousandsx(totalData.saleUnits || 0, monenyCode),
      saleUnitsPrev: toThousandsx(totalData.saleUnitsCompared || 0, monenyCode),
      saleUnitsChange: formatPercent(totalData.saleUnitsChange, monenyCode),

      cpa: formatMoney1(totalData.cpa, monenyCode),
      cpaPrev: formatMoney1(totalData.cpaCompared, monenyCode),
      cpaChange: formatPercent(totalData.cpaChange, monenyCode),

      cvr: formatPercent(totalData.cvr, monenyCode),
      cvrPrev: formatPercent(totalData.cvrCompared, monenyCode),
      cvrChange: formatPercent(totalData.cvrChange, monenyCode),

      clickCvr: formatPercent(totalData.clickCvr, monenyCode),
      clickCvrPrev: formatPercent(totalData.clickCvrCompared, monenyCode),
      clickCvrChange: formatPercent(totalData.clickCvrChange, monenyCode),

      clickAcos: formatPercent(totalData.clickAcos, monenyCode),
      clickAcosPrev: formatPercent(totalData.clickAcosCompared, monenyCode),
      clickAcosChange: formatPercent(totalData.clickAcosChange, monenyCode),

      clickCpa: formatSpendsMoney(totalData.clickCpa, monenyCode),
      clickCpaPrev: formatSpendsMoney(totalData.clickCpaCompared, monenyCode),
      clickCpaChange: formatPercent(totalData.clickCpaChange, monenyCode),

      clickRoas: formatMoneyRoas(totalData.clickRoas, monenyCode),
      clickRoasPrev: formatMoneyRoas(totalData.clickRoasCompared, monenyCode),
      clickRoasChange: formatPercent(totalData.roasChange, monenyCode),

      // eslint-disable-next-line no-dupe-keys
      clickOrders: toThousandsx(totalData.clickOrders, monenyCode),
      clickOrdersPrev: toThousandsx(totalData.clickOrdersCompared, monenyCode),
      clickOrdersChange: formatPercent(totalData.clickOrdersChange, monenyCode),

      clickSaleUnits: toThousandsx(totalData.clickSaleUnits, monenyCode),
      clickSaleUnitsPrev: toThousandsx(totalData.clickSaleUnitsCompared, monenyCode),
      clickSaleUnitsChange: formatPercent(totalData.clickSaleUnitsChange, monenyCode),
      acos: formatPercent(totalData.acos, monenyCode),
      acosPrev: formatPercent(totalData.acosCompared, monenyCode),
      acosChange: formatPercent(totalData.acosChange, monenyCode),

      tacos: formatPercent(totalData.tacos, monenyCode),
      tacosPrev: formatPercent(totalData.tacosCompared, monenyCode),
      tacosChange: formatPercent(totalData.tacosChange, monenyCode),

      roas: formatMoneyRoas(totalData.roas, monenyCode),
      roasPrev: formatMoneyRoas(totalData.roasCompared, monenyCode),
      roasChange: formatPercent(totalData.roasChange, monenyCode),

      troas: formatMoneyRoas(totalData.troas, monenyCode),
      troasPrev: formatMoneyRoas(totalData.troasCompared, monenyCode),
      troasChange: formatPercent(totalData.troasChange, monenyCode),

      sales: formatSalesMoney(totalData.sales, monenyCode, 1),
      salesPrev: formatSalesMoney(totalData.salesCompared, monenyCode, 1),
      salesChange: formatPercent(totalData.salesChange, monenyCode),

      otherSales: formatSalesMoney(totalData.otherSales, monenyCode, 1),
      otherSalesPrev: formatSalesMoney(totalData.otherSalesCompared, monenyCode, 1),
      otherSalesChange: formatPercent(totalData.otherSalesChange, monenyCode),

      clickROAS: formatMoneyRoas(totalData.clickROAS, monenyCode),
      clickROASPrev: formatMoneyRoas(totalData.clickROASCompared, monenyCode),
      clickROASChange: formatPercent(totalData.clickROASChange, monenyCode),

      clickACOS: formatPercent(totalData.clickACOS, monenyCode),
      clickACOSPrev: formatPercent(totalData.clickACOSCompared, monenyCode),
      clickACOSChange: formatPercent(totalData.clickACOSChange, monenyCode),

      clickSales: formatSalesMoney(totalData.clickSales, monenyCode, 1),
      clickSalesPrev: formatSalesMoney(totalData.clickSalesCompared, monenyCode, 1),
      clickSalesChange: formatPercent(totalData.clickSalesChange, monenyCode),

      relatedClickSales: formatSalesMoney(totalData.relatedClickSales, monenyCode, 1),
      relatedClickSalesPrev: formatSalesMoney(totalData.relatedClickSalesCompared, monenyCode, 1),
      relatedClickSalesChange: formatPercent(totalData.relatedClickSalesChange, monenyCode),

      brandClickSales: formatSalesMoney(totalData.brandClickSales, monenyCode, 1),
      brandClickSalesPrev: formatSalesMoney(totalData.brandClickSalesCompared, monenyCode, 1),
      brandClickSalesChange: formatPercent(totalData.brandClickSalesChange, monenyCode),
      offLineSales: formatSalesMoney(totalData.offLineSales, monenyCode, 1),
      offLineSalesPrev: formatSalesMoney(totalData.offLineSalesCompared, monenyCode, 1),
      offLineSalesChange: formatPercent(totalData.offLineSalesChange, monenyCode),
      pickUpnDeliverySales: formatSalesMoney(totalData.pickUpnDeliverySales, monenyCode, 1),
      pickUpnDeliverySalesPrev: formatSalesMoney(totalData.pickUpnDeliverySalesCompared, monenyCode, 1),
      pickUpnDeliverySalesChange: formatPercent(totalData.pickUpnDeliverySalesChange, monenyCode),

      pickUpnDeliveryClickSales: formatSalesMoney(totalData.pickUpnDeliveryClickSales, monenyCode, 1),
      pickUpnDeliveryClickSalesPrev: formatSalesMoney(totalData.pickUpnDeliveryClickSalesCompared, monenyCode, 1),
      pickUpnDeliveryClickSalesChange: formatPercent(totalData.pickUpnDeliveryClickSalesChange, monenyCode),

      pickUpnDeliveryRelatedClickSales: formatSalesMoney(totalData.pickUpnDeliveryRelatedClickSales, monenyCode, 1),
      pickUpnDeliveryRelatedClickSalesPrev: formatSalesMoney(totalData.pickUpnDeliveryRelatedClickSalesCompared, monenyCode, 1),
      pickUpnDeliveryRelatedClickSalesChange: formatPercent(totalData.pickUpnDeliveryRelatedClickSalesChange, monenyCode),

      pickUpnDeliveryBrandClickSales: formatSalesMoney(totalData.pickUpnDeliveryBrandClickSales, monenyCode, 1),
      pickUpnDeliveryBrandClickSalesPrev: formatSalesMoney(totalData.pickUpnDeliveryBrandClickSalesCompared, monenyCode, 1),
      pickUpnDeliveryBrandClickSalesChange: formatPercent(totalData.pickUpnDeliveryBrandClickSalesChange, monenyCode),

      shippingSales: formatSalesMoney(totalData.shippingSales, monenyCode, 1),
      shippingSalesPrev: formatSalesMoney(totalData.shippingSalesCompared, monenyCode, 1),
      shippingSalesChange: formatPercent(totalData.shippingSalesChange, monenyCode),

      shippingClickSales: formatSalesMoney(totalData.shippingClickSales, monenyCode, 1),
      shippingClickSalesPrev: formatSalesMoney(totalData.shippingClickSalesCompared, monenyCode, 1),
      shippingClickSalesChange: formatPercent(totalData.shippingClickSalesChange, monenyCode),

      shippingRelatedClickSales: formatSalesMoney(totalData.shippingRelatedClickSales, monenyCode, 1),
      shippingRelatedClickSalesPrev: formatSalesMoney(totalData.shippingRelatedClickSalesCompared, monenyCode, 1),
      shippingRelatedClickSalesChange: formatPercent(totalData.shippingRelatedClickSalesChange, monenyCode),

      shippingBrandClickSales: formatSalesMoney(totalData.shippingBrandClickSales, monenyCode, 1),
      shippingBrandClickSalesPrev: formatSalesMoney(totalData.shippingBrandClickSalesCompared, monenyCode, 1),
      shippingBrandClickSalesChange: formatPercent(totalData.shippingBrandClickSalesChange, monenyCode),

      ntbOrders: toThousandsx(totalData.ntbOrders, monenyCode),
      ntbOrdersPrev: toThousandsx(totalData.ntbOrdersCompared, monenyCode),
      ntbOrdersChange: formatPercent(totalData.ntbOrdersCompared, monenyCode),

      ntbOrdersPercent: formatPercent(totalData.ntbOrdersPercent, monenyCode),
      ntbOrdersPercentPrev: formatPercent(totalData.ntbOrdersPercentCompared, monenyCode),
      ntbOrdersPercentChange: formatPercent(totalData.ntbOrdersPercentChange, monenyCode),

      ntbSales: formatSalesMoney(totalData.ntbSales, monenyCode, 1),
      ntbSalesPrev: formatSalesMoney(totalData.ntbSalesCompared, monenyCode, 1),
      ntbSalesChange: formatPercent(totalData.ntbSalesChange, monenyCode),

      ntbSalesPercent: formatPercent(totalData.ntbSalesPercent, monenyCode),
      ntbSalesPercentPrev: formatPercent(totalData.ntbSalesPercentCompared, monenyCode),
      ntbSalesPercentChange: formatPercent(totalData.ntbSalesPercentChange, monenyCode),

      ntbUnits: toThousandsx(totalData.ntbUnits, monenyCode),
      ntbUnitsPrev: toThousandsx(totalData.ntbUnitsCompared, monenyCode),
      ntbUnitsChange: toThousandsx(totalData.ntbUnitsChange, monenyCode),

      ntbUnitsPercent: formatPercent(totalData.ntbUnitsPercent, monenyCode),
      ntbUnitsPercentPrev: formatPercent(totalData.ntbUnitsPercentCompared, monenyCode),
      ntbUnitsPercentChange: formatPercent(totalData.ntbUnitsPercentChange, monenyCode),

      dailyBudgetPrev: formatdailyBudget(totalData.dailyBudgetCompared, monenyCode),
      dailyBudgetChange: formatPercent(totalData.dailyBudgetChange, monenyCode),

      remainingDailyBudget: formatMoney(totalData.remainingDailyBudget || 0, monenyCode),
      remainingDailyBudgetPrev: formatMoney(totalData.remainingDailyBudgetCompared || 0, monenyCode, monenyCode),
      remainingDailyBudgetChange: formatPercent(totalData.remainingDailyBudgetChange || 0, monenyCode),

      revenue: formatMoney(totalData.revenue, monenyCode),
      revenuePrev: formatMoney(totalData.revenueCompared, monenyCode),
      revenueChange: formatPercent(totalData.revenueChange, monenyCode),

      organicRevenue: formatMoney(totalData.organicRevenue, monenyCode),
      organicRevenuePrev: formatMoney(totalData.organicRevenueCompared, monenyCode),
      organicRevenueChange: formatPercent(totalData.organicRevenueChange, monenyCode),

      buyBoxPct: formatPercent100(totalData.buyBoxPct, monenyCode),
      buyBoxPctPrev: formatPercent100(totalData.buyBoxPctCompared, monenyCode),
      buyBoxPctChange: formatPercent(totalData.buyBoxPctChange, monenyCode),

      availabilityPct: formatPercent100(totalData.availabilityPct, monenyCode),
      availabilityPctPrev: formatPercent100(totalData.availabilityPctCompared, monenyCode),
      availabilityPctChange: formatPercent(totalData.availabilityPctChange, monenyCode),

      pageViews: toThousands(totalData.pageViews, monenyCode),
      pageViewsPrev: toThousands(totalData.pageViewsCompared, monenyCode),
      pageViewsChange: formatPercent(totalData.pageViewsChange, monenyCode),

      asp: formatMoney(totalData.asp, monenyCode),
      aspPrev: formatMoney(totalData.aspCompared, monenyCode),
      aspChange: formatPercent(totalData.aspChange, monenyCode),

      cpm: formatMoney(totalData.cpm, monenyCode),
      cpmPrev: formatMoney(totalData.cpmCompared, monenyCode),
      cpmChange: formatPercent(totalData.cpmChange, monenyCode),

      cost: formatSalesMoney(totalData.cost, monenyCode, 1),
      attributed_conversion: toThousandsx(totalData.attributed_conversion, monenyCode),
      attributed_units_ordered: toThousandsx(totalData.attributed_units_ordered, monenyCode),
      attributed_sales: formatSalesMoney(totalData.attributed_sales, monenyCode, 1),

      //SameSKU
      SameSkuSales: formatSalesMoney(totalData.SameSkuSales, monenyCode, 1),
      SameSkuSalesPrev: formatSalesMoney(totalData.SameSkuSalesCompar, monenyCode, 1),
      SameSkuSalesChange: formatPercent(totalData.SameSkuSalesChange, monenyCode),

      SameSkuSaleUnits: toThousandsx(totalData.SameSkuSaleUnits, monenyCode),
      SameSkuSaleUnitsPrev: toThousandsx(totalData.SameSkuSaleUnitsCompar, monenyCode),
      SameSkuSaleUnitsChange: formatPercent(totalData.SameSkuSaleUnitsChange, monenyCode),

      SameSkuConversions: toThousandsx(totalData.SameSkuConversions, monenyCode),
      SameSkuConversionsPrev: toThousandsx(totalData.SameSkuConversionsCompar, monenyCode),
      SameSkuConversionsChange: formatPercent(totalData.SameSkuConversionsChange, monenyCode),

      SameSkuROAS: formatMoneyRoas(totalData.SameSkuROAS, monenyCode),
      SameSkuROASPrev: formatMoneyRoas(totalData.SameSkuROASCompar, monenyCode),
      SameSkuROASChange: formatPercent(totalData.SameSkuROASChange, monenyCode),

      SameSkuACOS: formatPercent(totalData.SameSkuACOS, monenyCode),
      SameSkuACOSPrev: formatPercent(totalData.SameSkuACOSCompar, monenyCode),
      SameSkuACOSChange: formatPercent(totalData.SameSkuACOSChange, monenyCode),

      SameSkuCPA: formatMoney1(totalData.SameSkuCPA, monenyCode),
      SameSkuCPAPrev: formatMoney1(totalData.SameSkuCPACompar, monenyCode),
      SameSkuCPAChange: formatPercent(totalData.SameSkuCPAChange, monenyCode),

      SameSkuCVR: formatPercent(totalData.SameSkuCVR, monenyCode),
      SameSkuCVRPrev: formatPercent(totalData.SameSkuCVRCompar, monenyCode),
      SameSkuCVRChange: formatPercent(totalData.SameSkuCVRChange, monenyCode),

      cumulativeReach: toThousandsx(totalData.cumulativeReach, monenyCode),
      cumulativeReachPrev: formatPercent(totalData.cumulativeReachCompar, monenyCode),
      cumulativeReachChange: formatPercent(totalData.cumulativeReachChange, monenyCode),

      avgImpressionsFrequency: toThousandsx(totalData.avgImpressionsFrequency, monenyCode),
      avgImpressionsFrequencyPrev: formatPercent(totalData.avgImpressionsFrequencyCompar, monenyCode),
      avgImpressionsFrequencyChange: formatPercent(totalData.avgImpressionsFrequencyChange, monenyCode)
    }
    if (totalType == "Targeting") {
      delete totalRows.NewToBrandOrderRate
      delete totalRows.NewToBrandOrders
      delete totalRows.NewToBrandOrdersPercentage
      //delete totalRows.NewToBrandSales
      delete totalRows.NewToBrandSalesPercentage
      delete totalRows.NewToBrandUnitsOrdered
      delete totalRows.NewToBrandUnitsOrderedPercentage

      delete totalRows.NewToBrandOrderRatePrev
      delete totalRows.NewToBrandOrdersPrev
      delete totalRows.NewToBrandOrdersPercentagePrev
      //delete totalRows.NewToBrandSalesPrev
      delete totalRows.NewToBrandSalesPercentagePrev
      delete totalRows.NewToBrandUnitsOrderedPrev
      delete totalRows.NewToBrandUnitsOrderedPercentagePrev

      delete totalRows.NewToBrandOrderRateChange
      delete totalRows.NewToBrandOrdersChange
      delete totalRows.NewToBrandOrdersPercentageChange
      //delete totalRows.NewToBrandSalesChange
      delete totalRows.NewToBrandSalesPercentageChange
      delete totalRows.NewToBrandUnitsOrderedChange
      delete totalRows.NewToBrandUnitsOrderedPercentageChange
    }
    return totalRows
  }
  return { getPacvueFormatProIndexOld: getPacvueFormatProIndex }
}
export function PacvueFormatNumber({ val } = {}) {
  const numReg = /^-?\d+(.\d+)?$/
  if (!numReg.test(val)) {
    val = 0
  } else {
    val = parseFloat(val)
  }
  return val
}
