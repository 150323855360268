import { createRouter, createWebHistory } from "vue-router"
import Main from "@pacvue/frame/layout/Main.vue"
import { app } from "@pacvue/frame"
import { useRouterInterceptor as useWarRoomRouterInterceptor } from "@/views/Optimization/WarRoom/useRouterInterceptor"
import { useRouterInterceptor as useProductCenterRouterInterceptor } from "@/views/ProductCenter/useRouterInterceptor"
import { useRouterInterceptor as useExplorerDetailRouterInterceptor } from "@/views/Advertising/AdvertisingExplorerDetail/useRouterInterceptor"
import { useRouterInterceptor as useCampaignTagRouterInterceptor } from "@/views/Advertising/CampaignTag/useRouterInterceptor"
var warRoomInterceptor = useWarRoomRouterInterceptor() || {}
var productCenterInterceptor = useProductCenterRouterInterceptor() || {}
let exploreDetailInterceptor = useExplorerDetailRouterInterceptor() || {}
let campaignTagRouterInterceptor = useCampaignTagRouterInterceptor() || {}
const router = createRouter({
  history: createWebHistory(),
  routes: [
    // {
    //   path: "/DSP*",
    //   component: Main,
    //   meta: {
    //     auth: true,
    //     id: -1,
    //     noBreadcrumb: true
    //   },
    //   redirect: "/DSP/:DSP+",
    //   children: [
    //     {
    //       path: "/DSP/:DSP+",
    //       component: () => import("@/views/Dsp/index.vue")
    //     }
    //   ]
    // },
    // {
    //   path: "/DSPShare",
    //   component: Main,
    //   meta: {
    //     auth: true,
    //     noBreadcrumb: true,
    //     id: -1
    //   },
    //   redirect: "/DSPShare/:DSP+",
    //   children: [
    //     {
    //       path: "/DSPShare/:DSP+",
    //       component: () => import("@/views/Dsp/index.vue")
    //     }
    //   ]
    // },
    {
      path: "/",
      component: Main,
      meta: {
        auth: true,
        id: -1
      },
      redirect: "/Home",
      children: [
        {
          path: "/Home",
          component: () => import("@/views/Home/index.vue"),
          name: "Home"
          // meta: {
          //   noBreadcrumb: true
          // }
        },
        {
          path: "/Share/Index",
          component: () => import("@/views/Share/sharePage/index.vue")
        },
        {
          path: "/Share/AnalyticDashboard",
          component: () => import("@/views/Share/sharePage/index.vue")
        },
        {
          path: "/Training",
          name: "Training Doc & Video",
          component: () => import("@/views/Training/index.vue"),
          meta: { title: "Training Doc & Video" }
        },
        {
          path: "/ProductCenter/ProductDashboard",
          ...productCenterInterceptor,
          component: () => import("@/views/ProductCenter/ProductDashboard.vue"),
          name: "ProductDashboard",
          meta: { title: "ProductDashboard", id: 2 }
        },
        {
          path: "/ProductCenter/ProductListing",
          component: () => import("@/views/ProductCenter/Listing.vue"),
          name: "Product Listing",
          meta: { title: "ProductDashboard", id: 2, isShowTime: true }
        },
        {
          path: "/ProductCenter/Order",
          component: () => import("@/views/ProductCenter/ProductOrder.vue"),
          name: "Order",
          meta: { title: "ProductDashboard", id: 2 }
        },

        {
          path: "/ProductCenter/ProductListing/ProductDetail",
          ...productCenterInterceptor,
          component: () => import("@/views/ProductCenter/ProductDetail.vue"),
          name: "Product Detail",
          meta: { parent: "Product Listing" }
        },
        {
          path: "/ProductCenter/ProductNonVC",
          component: () => import("@/views/ProductCenter/ProductNonVc.vue"),
          name: "ProductNonVC",
          meta: { parent: "ProductDashboard", isShowTime: true }
        },
        {
          // /Share/ProductDashboard
          path: "/Share/ProductDashboard",
          component: () => import("@/views/Share/sharePage/index.vue"),
          name: "ProductDashboardShare"
          // meta: { parent: 'Product Listing' }
        },
        {
          path: "/ProductCenter/Eligibility",
          component: () => import("@/views/ProductCenter/EligibilityInsight.vue"),
          name: "Eligibility Insight",
          meta: { title: "EligibilityInsight", id: 5, isShowTime: false }
        },
        {
          path: "/ProductCenter/Eligibility/ASINLists",
          component: () => import("@/views/ProductCenter/ASINLists.vue"),
          name: "ASIN List",
          meta: { parent: "Eligibility Insight", isShowTime: false }
        },
        // {
        //   path: "/Budget/BudgetDashboard",
        //   component: () => import("@/views/BudgetManager/index.vue"),
        //   name: "Budget Manager"
        // },
        // {
        //   path: "/Budget/BudgetManager",
        //   component: () => import("@/views/BudgetManager/BudgetSetting.vue")
        // },
        // {
        //   path: "/Budget/CalendarTemplateManager",
        //   component: () => import("@/views/BudgetManager/CalendarTemplateManager.vue"),
        //   name: "Calendar Template Manager"
        // },
        // {
        //   path: "/Budget/OperationLog",
        //   component: () => import("@/views/BudgetManager/OperationLog.vue"),
        //   name: "Operation Log",
        //   meta: {
        //     isShowTime: true
        //   }
        // },
        // {
        //   path: "/Budget/BulkOperations",
        //   component: () => import("@/views/BudgetManager/budgetBulkOperations/index.vue"),
        //   name: "Bulk Operations"
        // },
        // {
        //   path: "/Budget/BulkOperationsLog",
        //   component: () => import("@/views/BudgetManager/budgetBulkOperations/log.vue"),
        //   name: "Bulk Operations Log",
        //   meta: {
        //     parent: "Budget Operations",
        //     link: "/Budget/BulkOperations",
        //     showCompareCheck: true
        //   }
        // },
        {
          path: "/Report/index",
          component: () => import("@/views/Report/DefaultReport/DefaultReport.vue"),
          name: "Default Report"
        },
        {
          path: "/Report/MyReport",
          component: () => import("@/views/Report/MyReport/index.vue"),
          name: "My Report"
        },
        {
          path: "/createMyReport",
          component: () => import("@/views/Report/MyReport/createMyReport.vue"),
          name: "create My Report"
          // meta: { parent: 'My Report' }
        },
        {
          path: "/viewHistoryReport",
          component: () => import("@/views/Report/MyReport/viewHistoryReport.vue"),
          name: $t("key1563"),
          meta: { parent: "My Report" }
        },
        // {
        //   path: '/ShareCenter/index',
        //   component: () => import('../../../crossretail/src/views/ShareCenter/index.vue'),
        //   name: 'Share Center'
        // },
        {
          path: "/Report/MarketPlaceDashboard",
          component: () => import("@/views/Report/Marketplace/MarketPlaceIndex.vue"),
          name: "Marketplace Dashboard"
        },
        {
          path: "/Report/KeywordPerformanceReport",
          component: () => import("@/views/Report/DefaultReport/KeywordPerformanceReport.vue"),
          name: $t("key1535"),
          meta: { parent: "Default Report" }
        },
        // {
        //   path: "/Report/CustomDashboard",
        //   component: () => import("@/views/customDashboard/index.vue"),
        //   name: $t("Custom Dashboard")
        // },
        // {
        //   path: "/Report/CustomDashboard/Create",
        //   component: () => import("@/views/customDashboard/Dashboard/CreateDashboard.vue"),
        //   name: "Create Dashboard",
        //   meta: {
        //     noSideBar: false
        //   }
        // },
        // {
        //   path: "/Report/CustomDashboard/Edit",
        //   component: () => import("@/views/customDashboard/Dashboard/CreateDashboard.vue")
        // },
        // {
        //   path: "/Report/CustomDashboard/ViewSample",
        //   component: () => import("@/views/customDashboard/Dashboard/CreateDashboard.vue")
        // },
        // {
        //   path: "/Report/CustomDashboard/Detail",
        //   component: () => import("@/views/customDashboard/Detail/DashboardDetail.vue"),
        //   meta: {
        //     parent: "Custom Dashboard",
        //     isShowTime: true
        //   }
        // },
        {
          path: "/Report/AccountProfilesSpendsReport",
          component: () => import("@/views/Report/DefaultReport/AccountProfilesSpendsReport.vue"),
          name: $t("amskey622"),
          meta: { parent: "Default Report" }
        },
        {
          path: "/Report/ProductEligibilityReport",
          component: () => import("@/views/Report/DefaultReport/ProductEligibilityReport.vue"),
          name: $t("amskey615"),
          meta: { parent: "Default Report" }
        },
        {
          path: "/Report/ASINBrandHaloReport",
          component: () => import("@/views/Report/DefaultReport/ASINBrandHaloReport.vue"),
          name: $t("amskey613"),
          meta: { parent: "Default Report" }
        },
        {
          path: "/Report/ProductPerformanceReport",
          component: () => import("@/views/Report/DefaultReport/ProductPerformanceReport.vue"),
          name: $t("amskey611"),
          meta: { parent: "Default Report" }
        },
        {
          path: "/Report/BrandMetricsReport",
          component: () => import("@/views/Report/DefaultReport/BrandMetricsReport.vue"),
          name: $t("amskey627"),
          meta: { parent: "Default Report" }
        },
        {
          path: "/Report/ProfileReport",
          component: () => import("@/views/Report/DefaultReport/ProfileReport.vue"),
          name: $t("key1492"),
          meta: { parent: "Default Report" }
        },
        {
          path: "/Report/CampaignReport",
          component: () => import("@/views/Report/DefaultReport/CampaignReport.vue"),
          name: $t("key1523"),
          meta: { parent: "Default Report" }
        },
        {
          path: "/Report/CampaignHoulryReport",
          component: () => import("@/views/Report/DefaultReport/CampaignHoulryReport/index.vue"),
          name: $t("Campaign Hourly Report"),
          meta: { parent: "Default Report" }
        },
        {
          path: "/Report/PortfolioCampaignTagReport",
          component: () => import("@/views/Report/DefaultReport/PortfolioCampaignTagReport.vue"),
          name: $t("amskey591"),
          meta: { parent: "Default Report" }
        },

        {
          path: "/Report/AccountAuditReport",
          component: () => import("@/views/Report/DefaultReport/AccountAuditReport.vue"),
          name: $t("amskey658")
          // meta: {
          //   noBreadcrumb: true
          // }
        },
        {
          path: "/Report/JJReport",
          component: () => import("@/views/Report/DefaultReport/JJReport/index.vue"),
          name: $t("amskey2230"),
          meta: { parent: "Default Report" }
        },
        {
          path: "/Report/TagPivotReport",
          component: () => import("@/views/Report/DefaultReport/TagPivotReport.vue"),
          name: "Tag Pivot Report",
          meta: { parent: "Default Report" }
        },

        // {
        //   path: '/Report/MyReport',
        //   component: () => import('@/views/Report/MyReport/index.vue'),
        //   name: 'My Report'
        // },
        // {
        //   path: '/createMyReport',
        //   component: () => import('@/views/Report/MyReport/createMyReport.vue'),
        //   name: 'create My Report'
        // },

        {
          path: "/Analytics/Index",
          component: () => import("@/views/Report/AnalyticsDashboard/index.vue"),
          name: "Analytics DashBoard"
        },
        {
          path: "/Analysis/Competitive",
          component: () => import("@/views/CompetitorAnalysis/index.vue"),
          name: "Competitive"
        },
        {
          path: "/Analysis/SOVDashboard",
          component: () => import("@/views/CompetitorAnalysis/SOVDashboard.vue"),
          name: "SOV Dashboard",
          meta: {
            parent: "Competitive",
            isShowTime: true
          }
        },
        {
          path: "/Analysis/SOVDaypartAnalytics",
          component: () => import("@/views/CompetitorAnalysis/SOVDaypartAnalytics.vue"),
          name: "SOV Daypart Analytics",
          meta: {
            parent: "Competitive",
            isShowTime: true
          }
        },
        {
          path: "/Analysis/SOVDaypartAnalyticsDetail",
          component: () => import("@/views/CompetitorAnalysis/SOVDaypartAnalyticsDetail.vue"),
          name: "SOV Daypart Analytics Detail",
          meta: {
            parent: "Competitive",
            isShowTime: true
          }
        },
        {
          path: "/Analysis/SOVDashboardPat",
          component: () => import("@/views/CompetitorAnalysis/SOVDashboard.vue"),
          name: "SOVDashboardPat",
          meta: {
            parent: "Competitive",
            isShowTime: true
          }
        },
        {
          path: "/Share/SOVDashboard",
          component: () => import("@/views/Share/sharePage/index.vue"),

          // component: () => import('@/views/CompetitorAnalysis/ShareSOVDashboard.vue'),
          // meta: {
          //   isShowTime: true
          // }
          meta: {
            title: "test 1",
            id: 1,
            isShowTime: false,
            showCompareCheck: false,
            breadcrumb: [
              {
                name: "Price Tracker",
                link: "/Price Tracker/ShareDashboard"
              }
            ]
          }
        },
        {
          path: "/Share/SOVBrand",
          component: () => import("@/views/Share/sharePage/index.vue")
        },
        {
          path: "/Share/SOVKeyword",
          component: () => import("@/views/Share/sharePage/index.vue")
        },
        {
          path: "/Share/SOVAsin",
          component: () => import("@/views/Share/sharePage/index.vue")
        },
        {
          path: "/Analysis/SOVBrand",
          component: () => import("@/views/CompetitorAnalysis/SOVBrand.vue"),
          meta: {
            parent: "Competitive",
            isShowTime: true
          },
          name: "SOV Brand"
        },
        {
          path: "/Analysis/SOVBrandPat",
          component: () => import("@/views/CompetitorAnalysis/SOVBrandPat.vue"),
          meta: {
            parent: "Competitive",
            isShowTime: true
          },
          name: "SOV BrandPat"
        },
        {
          path: "/Analysis/SOVKeyword",
          component: () => import("@/views/CompetitorAnalysis/SOVKeyword.vue"),
          meta: {
            parent: "Competitive",
            isShowTime: true
          },
          name: "SOV Keyword"
        },
        {
          path: "/Analysis/SOVAsin",
          component: () => import("@/views/CompetitorAnalysis/SOVAsin.vue"),
          meta: {
            parent: "Competitive",
            isShowTime: true
          },
          name: "SOV ASIN"
        },
        {
          path: "/Analysis/SOVInsight",
          component: () => import("@/views/CompetitorAnalysis/SOVInsight.vue"),
          meta: {
            parent: "Competitive",
            isShowTime: true
          },
          name: "SOVInsight"
        },
        {
          path: "/Analysis/ManageKeywordTag",
          component: () => import("@/views/CompetitorAnalysis/ManageKeywordTag.vue"),
          name: $t("key440"),
          meta: {
            parent: "Competitive",
            isShowTime: false
          }
        },
        {
          path: "/Analysis/CompetitorAsinAnalysis",
          component: () => import("@/views/CompetitorAnalysis/AsinDetail.vue"),
          name: "ASIN SOV Analysis",
          meta: {
            parent: "Competitive",
            isShowTime: true
          }
        },
        {
          path: "/Share/Analysis/CompetitorAsinAnalysis",
          component: () => import("@/views/CompetitorAnalysis/AsinDetail.vue"),
          name: "Share ASIN SOV Analysis",
          meta: {
            parent: "Competitive",
            isShowTime: true,
            showCompareCheck: false
          }
        },
        {
          path: "/Analysis/BrandsMapping",
          component: () => import("@/views/CompetitorAnalysis/BrandsMapping.vue"),
          meta: {
            parent: "Competitive",
            isShowTime: true
          },
          name: "SOV Brands Mapping"
        },
        {
          path: "/Analysis/KeywordDetail",
          component: () => import("@/views/CompetitorAnalysis/KeywordDetailComp.vue"),
          name: $t("key399"),
          meta: {
            parent: "Competitive",
            isShowTime: true,
            showCompareCheck: true,
            breadcrumb: [
              {
                name: "Competitive",
                link: "/"
              },
              {
                name: "KeywordDetail"
                // link: '/login'
              }
            ]
          }
        },
        {
          path: "/Analysis/PATDetail",
          component: () => import("@/views/CompetitorAnalysis/PATDetail.vue"),
          name: "PATDetail",
          meta: {
            parent: "Competitive",
            isShowTime: true,
            showCompareCheck: true,
            breadcrumb: [
              {
                name: "Competitive",
                link: "/"
              },
              {
                name: "PAT Detail"
                // link: '/login'
              }
            ]
          }
        },
        {
          path: "/Analysis/Redirect",
          redirect: "/Analysis/KeywordDetail"
        },
        {
          path: "/Analysis/ProductTracking",
          name: "Product Tracking",
          component: () => import("@/views/ProductTracking/index.vue"),
          meta: {
            title: "ProductTracking",
            id: 1,
            isShowTime: false,
            showCompareCheck: false,
            breadcrumb: [
              {
                name: "ProductTracking",
                link: "/Analysis/ProductTracking"
              }
            ]
          }
        },
        {
          path: "/Analysis/ProductTrackingDetail",
          component: () => import("@/views/ProductTracking/detail.vue"),
          name: "Product Tracking Details",
          meta: {
            title: "Product Detail",
            id: 1,
            isShowTime: true,
            showCompareCheck: true,
            breadcrumb: [
              {
                name: "ProductTracking",
                link: "/Analysis/ProductTracking"
              },
              {
                name: "Product Detail",
                link: "/Analysis/ProductTrackingDetail"
              }
            ]
          }
        },
        {
          path: "/PriceTracker/Home",
          component: () => import("@/views/PriceTracker/index.vue"),
          meta: {
            title: "Price Tracker",
            id: 1,
            isShowTime: false,
            showCompareCheck: false,
            breadcrumb: [
              {
                name: "Price Tracker",
                link: "/Price Tracker/Home"
              }
            ]
          }
        },
        {
          path: "/Share/PriceTrackerDashboard",
          component: () => import("@/views/Share/sharePage/index.vue"),
          meta: {
            title: "Price Tracker",
            id: 1,
            isShowTime: false,
            showCompareCheck: false,
            breadcrumb: [
              {
                name: "Price Tracker",
                link: "/Price Tracker/ShareDashboard"
              }
            ]
          }
        },
        {
          path: "/PriceTracker/PriceTrackerDetail",
          component: () => import("@/views/PriceTracker/detail.vue"),
          meta: {
            title: "Product Detail",
            id: 1,
            isShowTime: true,
            showCompareCheck: true,
            breadcrumb: [
              {
                name: "PriceTracker",
                link: "/PriceTracker/Home"
              },
              {
                name: "Product Detail",
                link: "/PriceTracker/PriceTrackerDetail"
              }
            ]
          }
        },
        {
          path: "/Research/PATResearch",
          component: () => import("@/views/PATResearch/index.vue"),
          meta: {
            title: "PAT Research",
            id: 1,
            isShowTime: false,
            showCompareCheck: true,
            breadcrumb: [
              {
                name: "PAT Research",
                link: "/Research/PATResearch"
              }
            ]
          },
          name: "PATResearch"
        },
        {
          path: "/Analysis/BrandAudit",
          component: () => import("@/views/BrandAudit/index.vue"),
          name: "Brand Audit",
          meta: {
            title: "Brand Audit",
            id: 1,
            isShowTime: false,
            showCompareCheck: true,
            breadcrumb: [
              {
                name: "Brand Audit",
                link: "/Analysis/BrandAudit"
              }
            ]
          }
        },
        {
          path: "/Analysis/AuditedASIN",
          component: () => import("@/views/BrandAudit/Detail/AuditedASIN.vue"),
          name: "Audited ASIN",
          meta: {
            parent: "Brand Audit",
            title: "Audited ASIN",
            id: 1,
            isShowTime: false,
            showCompareCheck: false
            // breadcrumb: [
            //   {
            //     name: 'Brand Audit',
            //     link: '/Analysis/BrandAudit'
            //   },
            //   {
            //     name: 'Audited ASIN',
            //     link: '/Analysis/AuditedASIN'
            //   }
            // ]
          }
        },
        {
          path: "/Research/KeywordsCopy",
          component: () => import("@/views/Research/Keyword/index.vue"),
          name: "Keyword Research"
        },
        {
          path: "/Research/HolidayKeywords",
          component: () => import("@/views/Research/HolidayKeywords/index.vue"),
          name: "Holiday Keywords"
        },

        {
          path: "/Research/Incrementality",
          component: () => import("@/views/Research/Incrementality/index.vue"),
          name: "Incrementality"
        },
        {
          path: "/Research/Incrementality/Products",
          component: () => import("@/views/Research/Incrementality/Products.vue"),
          name: "Products"
          // meta: {
          //   parent: "Incrementality"
          // }
        },
        {
          path: "/Research/Incrementality/Campaigns",
          component: () => import("@/views/Research/Incrementality/Campaigns.vue"),
          name: "Campaigns"
          // meta: {
          //   parent: "Incrementality"
          // }
        },
        {
          path: "/Research/Incrementality/Empty",
          component: () => import("@/views/Research/Incrementality/EmptyPage.vue"),
          name: "IncrementalityEmpty"
          // meta: {
          //   parent: "Incrementality"
          // }
        },
        {
          path: "/Optimization/AutomationDashboard",
          component: () => import("@/views/Optimization/AutomationDashboard/index.vue"),
          meta: {
            id: 1,
            isShowTime: false,
            showCompareCheck: false
          },
          name: "AutomationDashboard"
        },
        {
          path: "/Optimization/AutomationDashboard/DashboardDetail",
          component: () => import("@/views/Optimization/AutomationDashboard/DashboardDetail/index.vue"),
          meta: {
            id: 1,
            isShowTime: false,
            showCompareCheck: false
          },
          name: "AutomationDashboardDetail"
        },
        {
          path: "/Optimization/AudienceResearch",
          component: () => import("@/views/Research/Audience/index.vue"),
          name: "Audience Research"
        },
        {
          path: "/Research/IncrementalityConsole",
          component: () => import("@/views/Research/ShoppingInsight/index.vue"),
          name: "Incrementality Console"
        },
        {
          path: "/Research/AsinLandScape",
          component: () => import("@/views/Research/AsinLandScape/index.vue"),
          name: "Asin LandScape",
          meta: { isShowTime: true }
        },
        {
          path: "/Research/BidLandScape",
          component: () => import("@/views/Research/BidLandScape/index.vue"),
          name: "Bid LandScape"
        },
        {
          path: "/Optimization/QueryAsinGraph",
          component: () => import("@/views/Research/QueryAsinGraph/index.vue"),
          meta: {
            title: "Query-Asin Graph",
            id: 1,
            isShowTime: false,
            showCompareCheck: false,
            breadcrumb: [
              {
                name: "Query-Asin Graph",
                link: "/Optimization/QueryAsinGraph"
              }
            ]
          },
          name: "QueryAsinGraph"
        },
        // {
        //   path: "/Setting",
        //   component: () => import("@/views/setting/index.vue"),
        //   meta: { title: "Setting", id: 2 }
        // },
        {
          path: "/Notification",
          component: () => import("@/views/setting/notification.vue"),
          meta: { title: "Notification", id: 2 },
          name: "Notification"
        },
        // {
        //   path: "/Message",
        //   component: () => import("@/views/message/message.vue"),
        //   name: $t("Message")
        //   // meta: { title: '',showBreadcrumb:false },
        // },
        // {
        //   path: "/Optimization/Dayparting",
        //   component: () => import("@/views/Optimization/Dayparting/index.vue"),
        //   name: "Dayparting Scheduler"
        // },
        // {
        //   path: "/Optimization/Dayparting/Setting",
        //   component: () => import("@/views/Optimization/Dayparting/pages/DaypartingSetting.vue"),
        //   name: "Scheduler Setting",
        //   meta: {
        //     parent: "Dayparting Scheduler"
        //   }
        // },
        // {
        //   path: "/Optimization/Dayparting/ApplyInfo",
        //   component: () => import("@/views/Optimization/Dayparting/pages/ApplyInfo.vue"),
        //   name: "Apply Detail",
        //   meta: {
        //     parent: "Dayparting Scheduler"
        //   }
        // },
        {
          path: "/Optimization/Schedule",
          component: () => import("@/views/Optimization/Schedule/index.vue"),
          name: "Budget Scheduler"
        },
        {
          path: "/Optimization/Schedule/Setting",
          component: () => import("@/views/Optimization/Schedule/pages/SchedulerSetting.vue"),
          name: $t("bskey131"),
          meta: {
            parent: "Budget Scheduler"
          }
        },
        // {
        //   path: "/Optimization/AutomationAI",
        //   component: () => import("@/views/Optimization/AI/index.vue"),
        //   name: "Campaign AI"
        // },
        // {
        //   path: "/Optimization/AutomationAI/launchAIforCampaigns",
        //   component: () => import("@/views/Optimization/AI/launchAI.vue"),
        //   name: "Launch AI for campaigns in batches",
        //   meta: {
        //     parent: "Campaign AI"
        //   }
        // },
        // {
        //   path: "/Optimization/AutomationAI/launchAIforTags",
        //   component: () => import("@/views/Optimization/AI/launchAI.vue"),
        //   name: "Launch AI for tags in batches",
        //   meta: {
        //     parent: "Campaign AI"
        //   }
        // },
        {
          path: "/Optimization/Schedule/ApplyInfo",
          component: () => import("@/views/Optimization/Schedule/pages/ApplyInfo.vue"),
          name: "Budget Detail",
          meta: {
            parent: "Budget Scheduler"
          }
        },
        {
          path: "/Campaign/BulkOperations",
          component: () => import("@/views/Advertising/BulkOperations/index.vue"),
          name: "CampaignBulkOperations"
        },
        //老版bulkoperation，只有adgrouptagbulk在用
        {
          path: "/Campaign/BulkAdgroupTag",
          component: () => import("@/views/Advertising/BulkOperations/indexOld.vue"),
          name: "AdgroupTagBulkOperations"
        },
        {
          path: "/Campaign/BulkOperationsLog",
          component: () => import("@/views/Advertising/BulkOperations/log.vue"),
          meta: {
            showCompareCheck: true
          },
          name: "CampaignBulkOperationsLog"
        },
        {
          path: "/Campaign/BulkOperationsDetail",
          component: () => import("@/views/Advertising/BulkOperations/detail.vue"),
          meta: {
            showCompareCheck: true
          }
        },
        {
          path: "/Campaign/CopyCampaignDetail",
          component: () => import("@/views/Advertising/BulkOperations/copyCampaignDetail.vue"),
          meta: {
            showCompareCheck: true
          }
        },
        {
          path: "/Campaign/CreateBulkDetail",
          component: () => import("@/views/Advertising/BulkOperations/createDetail.vue"),
          meta: {
            showCompareCheck: true
          }
        },
        {
          path: "/Campaign/BulkOperationResult",
          component: () => import("@/views/Advertising/BulkOperations/result.vue"),
          meta: {
            showCompareCheck: true
          }
        },
        {
          path: "/Campaign/Detail",
          alias: ["/Campaign/:subMenu/Detail", "/Campaign/AdGroup/SbAdsDetail"],
          component: () => import("@/views/Advertising/AdvertisingDetail/Advertising.vue"),
          meta: {
            id: 1,
            isShowTime: true,
            showCompareCheck: false
          },
          name: "CampaignDetail"
        },

        {
          path: "/Campaign/TargetSetting",
          component: () => import("@/views/Advertising/Campaign/TargetSetting/index.vue"),
          meta: {
            title: "Target Setting",
            isShowTime: false,
            showCompareCheck: false,
            breadcrumb: [
              {
                name: "Campaign",
                link: "/Campaign/Campaign"
              },
              {
                name: "Target Setting",
                link: "/Campaign/TargetSetting"
              }
            ]
          },
          name: "CampaignTargetSetting"
        },
        {
          path: "/Campaign/AdvertisingExplore",
          component: () => import("@/views/Advertising/AdvertisingExplorer/index.vue"),
          meta: {
            id: 1,
            isShowTime: true,
            showCompareCheck: true
          },
          name: "AdvertisingExplore"
        },
        {
          path: "/Campaign/ExploreDetail",
          component: () => import("@/views/Advertising/AdvertisingExplorerDetail/index.vue"),
          meta: {
            id: 1,
            isShowTime: true,
            showCompareCheck: true
          },
          name: "AdvertisingExploreDetail",
          ...exploreDetailInterceptor
        },
        {
          path: "/Campaign/Profile",
          component: () => import("@/views/Advertising/Profile/index.vue"),
          meta: {
            id: 1,
            isShowTime: true,
            showCompareCheck: true
          },
          name: "CampaignProfile"
        },
        {
          path: "/Campaign/Campaign",
          component: () => import("@/views/Advertising/Campaign/index.vue"),
          meta: {
            id: 1,
            isShowTime: true,
            showCompareCheck: true
          },
          name: "Campaign"
        },
        {
          name: $t("amskey994"),
          path: "/Campaign/HourlyTrendDetail",
          component: () => import("@/views/Advertising/Campaign/HourlyTrendDetail/index.vue"),
          meta: {
            parent: "Campaign",
            isShowTime: true,
            showCompareCheck: true
          }
        },
        {
          name: $t("amskey2912"),
          path: "/Campaign/CampaignSuperCustom",
          component: () => import("@/views/CampaignSuperCustom/index.vue"),
          meta: {
            parent: "Campaign"
          }
        },
        {
          path: "/oauth2/GoogleRedirect",
          component: () => import("@/views/Account/MyAccount.vue")
        },
        {
          path: "/Account/MyAccount",
          component: () => import("@/views/Account/MyAccount.vue"),
          name: "MyAccount"
        },
        {
          path: "/Guide",
          component: () => import("@/views/Account/Guide.vue"),
          meta: {
            noBreadcrumb: true
          }
        },
        {
          path: "/Binding",
          component: () => import("@/views/Account/Binding.vue"),
          name: "AccountBinding",
          meta: {
            noBreadcrumb: true
          }
        },
        {
          path: "/oauth2/redirect",
          component: () => import("@/views/Account/Binding.vue")
        },
        {
          path: "/oauth2/vendorredirect",
          component: () => import("@/views/Account/Binding.vue")
        },
        {
          path: "/bind/Setting",
          component: () => import("@/views/Account/Setting.vue")
        },
        {
          path: "/Account/Setting",
          component: () => import("@/views/Account/GeneralSetting.vue"),
          name: "Setting"
        },
        {
          path: "/Campaign/KeywordTagging",
          component: () => import("@/views/Advertising/KeywordTag/index.vue"),
          meta: {
            isShowTime: true,
            showCompareCheck: true
          },
          name: "KeywordTagging"
        },
        {
          path: "/Campaign/ASINTagging",
          component: () => import("@/views/Advertising/ASINTag/index.vue"),
          meta: {
            isShowTime: true,
            showCompareCheck: true
          },
          name: "AsinTag"
        },
        {
          path: "/Campaign/AdgroupTagging",
          component: () => import("@/views/Advertising/AdgroupTag/index.vue"),
          meta: {
            isShowTime: true,
            showCompareCheck: true
          },
          name: "AdgroupTagging"
        },
        {
          path: "/Campaign/CampaignTagging",
          component: () => import("@/views/Advertising/CampaignTag/index.vue"),
          meta: {
            isShowTime: true,
            showCompareCheck: true
          },
          ...campaignTagRouterInterceptor,
          name: "CampaignTagging"
        },
        {
          path: "/Campaign/AmazonPortfolio",
          component: () => import("@/views/Advertising/Portfolio/index.vue"),
          meta: {
            isShowTime: true,
            showCompareCheck: true
          },
          name: "AmazonPortfolio"
        },
        {
          path: "/Campaign/AdGroup",
          component: () => import("@/views/Advertising/Adgroup/index.vue"),
          meta: {
            isShowTime: true,
            showCompareCheck: true
          },
          name: "CampaignAdGroup"
        },
        {
          path: "/Campaign/Keyword",
          component: () => import("@/views/Advertising/Targeting/index.vue"),
          meta: {
            isShowTime: true,
            showCompareCheck: true
          },
          name: "Targeting"
        },
        {
          path: "/Campaign/KeywordOld",
          component: () => import("@/views/Advertising/TargetingOld/index.vue"),
          meta: {
            isShowTime: true,
            showCompareCheck: true
          },
          name: "AdTargeting"
        },
        {
          path: "/Campaign/Asin",
          component: () => import("@/views/Advertising/Asin/index.vue"),
          meta: {
            isShowTime: true,
            showCompareCheck: true
          },
          name: "CampaignAsin"
        },
        {
          path: "/Campaign/SbAds",
          alias: "/Campaign/SbAds",
          component: () => import("@/views/Advertising/SbAds/index.vue"),
          meta: {
            isShowTime: true,
            showCompareCheck: false
          },
          name: "SbAds"
        },
        {
          path: "/Campaign/Query",
          component: () => import("@/views/Advertising/SearchTerm/index.vue"),
          meta: {
            isShowTime: true,
            showCompareCheck: false
          },
          name: "CampaignSearchTerm"
        },
        {
          path: "/Campaign/QueryOld",
          component: () => import("@/views/Advertising/SearchTermOld/index.vue"),
          meta: {
            isShowTime: true,
            showCompareCheck: false
          },
          name: "CampaignQuery"
        },
        {
          path: "/Campaign/NegativeKeyword",
          component: () => import("@/views/Advertising/NegativeTargeting/index.vue"),
          name: "Negative Targeting",
          meta: {
            isShowTime: true,
            showCompareCheck: false
          }
        },
        {
          path: "/Campaign/CampaignMapping",
          component: () => import("@/views/Advertising/CampaignMapping/index.vue"),
          name: "Campaign Mapping",
          meta: {
            parent: "CampaignSearchTerm",
            id: 1,
            isShowTime: true,
            showCompareCheck: true
          }
        },
        {
          path: "/Campaign/CreateSPCampaign",
          component: () => import("@/views/Advertising/CreateCampaign/CreateSPCampaign.vue"),
          name: "Create SP Campaign",
          meta: {
            parent: "Campaign",
            isShowTime: false
          }
        },
        {
          path: "/Campaign/ManageNegativeTargetingList",
          component: () => import("@/views/Advertising/NegativeTargeting/ManageList/index.vue"),
          name: $t("amskey1136"),
          meta: {
            parent: "Negative Targeting",
            isShowTime: false
          }
        },
        {
          path: "/Campaign/ManageNegativeTargetingList/ListDetail",
          component: () => import("@/views/Advertising/NegativeTargeting/ManageList/pages/ListDetail.vue"),
          name: "List Detail",
          meta: {
            parent: "Manage Negative List",
            isShowTime: false
          }
        },
        {
          path: "/SDDashboard/Index",
          component: () => import("@/views/Sponsored/SponsoredIndex.vue"),
          name: "Sponsored Display Dashboard"
        },
        {
          path: "Share/ShareLink",
          component: () => import("@/views/Share/ShareLink.vue"),
          name: "Share Link"
        },
        {
          path: "/Share/ShareEmpty",
          component: () => import("@/views/Share/ShareEmpty.vue")
        },
        {
          path: "/Share/ShareCheckIndex",
          component: () => import("@/views/Share/ShareCheckPassword.vue")
        },
        {
          path: "/Campaign/CreateSBCampaign",
          component: () => import("@/views/Advertising/CreateCampaign/CreateSBCampaign.vue"),
          name: "Create SB Campaign",
          meta: {
            parent: "Campaign"
          }
        },
        {
          path: "/Campaign/CreateSDCampaign",
          component: () => import("@/views/Advertising/CreateCampaign/CreateSDCampaign.vue"),
          name: "Create SD Campaign",
          meta: {
            parent: "Campaign"
          }
        },
        {
          path: "/Campaign/CreateSTVCampaign",
          component: () => import("@/views/Advertising/CreateCampaign/CreateSTVCampaign.vue"),
          name: "Create STV Campaign",
          meta: {
            parent: "Campaign"
          }
        },
        {
          path: "/Share/ShareLinkConfig",
          component: () => import("@/views/Share/SharelinkConfig.vue"),
          meta: {
            parent: "Share Link"
          }
        },
        {
          path: "CategoryIntelligence/SearchTerm",
          component: () => import("@/views/CategoryIntelligence/SearchTerm.vue"),
          name: "SearchTerm",
          meta: {}
        },
        {
          path: "CategoryIntelligence/Product",
          component: () => import("@/views/CategoryIntelligence/Product.vue"),
          name: "Product",
          meta: {}
        },
        {
          path: "CategoryIntelligence/BrandAndSales",
          component: () => import("@/views/CategoryIntelligence/BrandAndSales.vue"),
          name: "BrandAndSales",
          meta: {
            menuMeta: [{ menuName: "Brand & Sales" }]
          }
        },
        {
          path: "CategoryIntelligence/ASINQuery",
          component: () => import("@/views/CategoryIntelligence/ASINQuery.vue"),
          name: "ASINQuery",
          meta: {}
        },
        {
          path: "CategoryIntelligence/ASINHistory",
          component: () => import("@/views/CategoryIntelligence/ASINHistory.vue"),
          name: "ASINHistory",
          meta: {}
        },
        {
          path: "/AuditCenter",
          component: () => import("@/views/Optimization/AuditCenter/pages/home/index.vue"),
          name: "Paid Search Audit"
        },
        {
          path: "/AuditCenter/Detail",
          component: () => import("@/views/Optimization/AuditCenter/detail.vue"),
          name: "Audit Detail",
          meta: {
            parent: "Paid Search Audit"
          }
        },
        {
          path: "/Campaign/ProfileDetail",
          component: () => import("@/views/Advertising/Profile/detail.vue"),
          meta: {
            title: "Profile Detail",
            id: 1,
            isShowTime: true,
            showCompareCheck: true,
            breadcrumb: [
              {
                name: "Profile",
                link: "/Camaign/Profile"
              },
              {
                name: "Profile Detail",
                link: "/Analysis/ProductTrackingDetail"
              }
            ]
          }
        },
        {
          path: "/AsyncData",
          component: () => import("@/views/AsyncData/AsyncData.vue"),
          name: "Synchronization"
          // meta: {
          //   noBreadcrumb: true
          // }
        },
        {
          path: "/Campaign/CreateAdgroup",
          component: () => import("@/views/Advertising/CreateCampaign/CreateAdgroupByCampaign.vue"),
          name: "Create Adgroup",
          meta: {
            parent: "CampaignAdGroup"
          }
        },
        {
          path: "/Campaign/CreateSBAd",
          alias: "/Campaign/AdGroup/CreateSBAd",
          component: () => import("@/views/Advertising/CreateCampaign/CreateSBAd.vue"),
          meta: {
            parent: "SbAds"
          },
          name: "CreateSBAd"
        },
        // {
        //   path: "/Optimization/ManualASINAI",
        //   component: () => import("@/views/SuperAi/manualSuperAI/index.vue")
        // },
        {
          path: "/Optimization/LiveMomentum",
          component: () => import("@/views/Optimization/WarRoom/index.vue"),
          ...warRoomInterceptor,
          meta: {
            title: "War Room",
            id: 1,
            isShowTime: false,
            showCompareCheck: false,
            breadcrumb: [
              {
                name: "War Room",
                link: "/Optimization/LiveMomentum"
              }
            ]
          }
        },
        {
          path: "/Optimization/LiveMomentumCreate",
          component: () => import("@/views/Optimization/WarRoom/Create/warroomCreate.vue"),
          meta: {
            title: "War Room",
            id: 1,
            isShowTime: false,
            showCompareCheck: false,
            breadcrumb: [
              {
                name: "War Room",
                link: "/Optimization/LiveMomentumCreate"
              }
            ]
          }
        },
        // {
        //   path: "/Optimization/LiveMomentum",
        //   component: () => import("@/views/Optimization/WarRoom/index.vue")
        // },
        // {
        //   path: "/Optimization/LiveMomentumCreate",
        //   component: () => import("@/views/Optimization/WarRoom/Create/warroomCreate.vue")
        // },
        // {
        //   path: "/ManualTool/CampaignAI",
        //   component: () => import("@/views/SuperAi/campaignAI/index.vue")
        // },
        {
          path: "/Campaign/CampaignToLEGO",
          component: () => import("@/views/Advertising/CreateCampaign/CreateCampaignToLeGo.vue"),
          meta: {
            isShowTime: true,
            showCompareCheck: true
          }
        }
      ]
    },

    {
      path: "/:pathMatch(.*)",
      redirect: "/"
    }
  ]
})
app.config.globalProperties.router = router
export default router
