const whiteLabelMap = new Map([
  [
    ["groupm.pacvue"],
    {
      isThemeFixed: true,
      themeColors: {
        themeColor: "#0014CC",
        themeBG3: "#0A2FFF",
        themeBG5: "#85A3FF",
        themeBG8: "#C2D4FF",
        themeBG9: "#E0EBFF",
        themeFSC: "#FDE212",
        themeRMH: "#fde212", // Menu 高亮色
        themeRMHT: "#000785", // Menu 高亮色中的文字色
        themeTHC: "#F0F7FF", // Table 专用 Hover 色
        themeRGB: "0, 20, 204"
      },
      globalLoadingSVG: "GroupM",
      logoPNG: "groupm.pacvue.com",
      tinyLogoPNG: "groupm.pacvue.com",
      loginIllustrator: "GroupM",
      favTitle: "GroupM",
      isPoweredByPacvueTitle: true,
      favIcon: "https://www.groupm.com/wp-content/themes/bigdrop-theme/dist/images/favicon-32x32.png",
      topBarTheme: "white",
      topBarBgColor: "#fff",
      topBarTextColor: "#45464f",
      sideBarBgColor: "#000050",
      sideBarBgSubColor: "#000333",
      homeTopCardBgColors: ["#85a3ff", "#6ce07b", "#ecc706"]
    }
  ],
  [
    ["commerce.lungemarketing"],
    {
      isThemeFixed: true,
      themeColors: {
        themeColor: "#87288F",
        themeBG3: "#a840b0",
        themeBG5: "#85A3FF",
        themeBG8: "#C2D4FF",
        themeBG9: "#E0EBFF",
        themeFSC: "#FDE212",
        themeRMH: "#fde212", // Menu 高亮色
        themeRMHT: "#56175d", // Menu 高亮色中的文字色
        themeTHC: "#F0F7FF", // Table 专用 Hover 色
        themeRGB: "0, 20, 204"
      },
      globalLoadingSVG: "",
      logoPNG: "",
      tinyLogoPNG: "",
      loginIllustrator: "",
      favTitle: "Lunge Marketing",
      favIcon: "",
      topBarTheme: "white",
      topBarBgColor: "#fff",
      topBarTextColor: "#45464f",
      sideBarBgColor: "#340d38",
      sideBarBgSubColor: "#170418",
      homeTopCardBgColors: ["#85a3ff", "#6ce07b", "#ecc706"]
    }
  ]
])

const hostname = window.location.hostname
const whiteLabelsConfig = []
const iter = whiteLabelMap.entries()
let entry
// 访问直至迭代器 Exhausted
while ((entry = iter.next().value) !== undefined) {
  whiteLabelsConfig.push(entry)
}

// 独立白名单站点 (无左上角跳转)
const independentHosts = ["commerce.lungemarketing.com"]

const headerTailoredHostsConfig = {
  "portal.revmo.com": {
    topBarBgColor: "#710000",
    topBarTextColor: "#ffffff",
    topBarTheme: "gray"
  },
  "ams.revivemedia.us": {
    topBarBgColor: "#8E68F4",
    topBarTextColor: "#ffffff",
    topBarTheme: "gray"
  },
  "audacy.pacvue": {
    topBarBgColor: "#7A3BCB",
    topBarTextColor: "#ffffff",
    topBarTheme: "gray"
  },
  canyonwallinsights: {
    topBarBgColor: "#1451FF",
    topBarTextColor: "#ffffff",
    topBarTheme: "gray"
  },
  "allianceclientsolutions.com": {
    topBarBgColor: "#0071CE",
    topBarTextColor: "#ffffff",
    topBarTheme: "gray"
  }
}

let headerTailoredConfig = null

for (const host in headerTailoredHostsConfig) {
  if (hostname.includes(host)) {
    headerTailoredConfig = headerTailoredHostsConfig[host]
    break
  }
}

export { whiteLabelsConfig, independentHosts, headerTailoredConfig }
