import request from "../request"

const { VITE_APP_GPT, VITE_APP_MENU_API, VITE_APP_APIENV, VITE_APP_NODEENV, VITE_APP_BASEURL, VITE_APP_META, VITE_APP_SOV2 } = import.meta.env

/**
 * @deprecated Copilot 1.0 GPT 接口
 */
export function queryfilter(data) {
  return request({
    url: `${VITE_APP_GPT}api/OpenAIGpt/queryfilter`,
    method: "post",
    fullData: true,
    cancelToken: true,
    headers: {
      Region: VITE_APP_APIENV
    },
    data
  })
}

export function feedbackApi(data) {
  return request({
    url: `${VITE_APP_MENU_API}user/feedback`,
    method: "post",
    fullData: true,
    headers: {
      Region: VITE_APP_APIENV
    },
    data
  })
}

export function downloadApi(data) {
  return request({
    url: `${VITE_APP_GPT}api/Download/DownloadData`,
    method: "post",
    responseType: "blob",
    fullData: true,
    headers: {
      Region: VITE_APP_APIENV
    },
    data
  })
}

export function CommentQuestionQuery(data) {
  return request({
    url: `${VITE_APP_GPT}api/FeadBack/CommentQuestionQuery`,
    method: "post",
    headers: {
      Region: VITE_APP_APIENV
    },
    data
  })
}

export function checkOpenAIkey() {
  return request({
    url: `${VITE_APP_GPT}api/OpenAIGpt/appkey`,
    method: "get",
    headers: {
      Region: VITE_APP_APIENV
    }
  })
}

export function verifySelfOpenAIkey(data) {
  return request({
    url: `${VITE_APP_GPT}api/OpenAIGpt/verifykey`,
    method: "post",
    headers: {
      Region: VITE_APP_APIENV
    },
    data
  })
}

// confirm insight
export function confirmInsight() {
  return request({
    url: `${VITE_APP_GPT}api/OpenAIGpt/enableInsight`,
    method: "post",
    headers: {
      Region: VITE_APP_APIENV
    }
  })
}

export function getSessionId() {
  return request({
    url: `${VITE_APP_GPT}api/id_generator/GetSessionId`,
    method: "get",
    headers: {
      Region: VITE_APP_APIENV
    }
  })
}

export function queryKnowledgeBase(data) {
  return request({
    url: `${VITE_APP_GPT}api/chatgpt/kb`,
    method: "post",
    fullData: true,
    cancelToken: true,
    headers: {
      Region: VITE_APP_APIENV
    },
    data
  })
}

export function queryData(data) {
  return request({
    url: `${VITE_APP_GPT}api/chatgpt/querydata`,
    method: "post",
    fullData: true,
    cancelToken: true,
    headers: {
      Region: VITE_APP_APIENV
    },
    data
  })
}

export function insightApi(data) {
  return request({
    url: `${VITE_APP_GPT}api/chatgpt/insight`,
    method: "post",
    fullData: true,
    cancelToken: true,
    headers: {
      Region: VITE_APP_APIENV
    },
    data
  })
}

export function insightTag(data) {
  return request({
    url: `${VITE_APP_GPT}api/chatgpt/insightTag`,
    method: "post",
    fullData: true,
    cancelToken: true,
    headers: {
      Region: VITE_APP_APIENV
    },
    data
  })
}

export function saveFreshmanGuidanceAction(data) {
  return request({
    url: `${VITE_APP_GPT}api/FeadBack/FirstCopilotFeedback`,
    method: "post",
    headers: {
      Region: VITE_APP_APIENV
    },
    data
  })
}

export function getAWSKnowledgeBaseToken() {
  return request({
    url: `${VITE_APP_GPT}api/OpenAIGpt/getAWSLoginInfo`,
    method: "get",
    headers: {
      Region: VITE_APP_APIENV
    }
  })
}

export function postSaveKnowledgeBaseLog(data) {
  return request({
    url: `${VITE_APP_GPT}api/OpenAIGpt/logKb`,
    method: "post",
    headers: {
      Region: VITE_APP_APIENV
    },
    data
  })
}

export function dynamicRequest(urlSuffix, data) {
  return request({
    url: `${VITE_APP_GPT}${urlSuffix}`,
    method: "post",
    headers: {
      Region: VITE_APP_APIENV
    },
    cancelToken: true,
    data
  })
}

export function getCampaignTagsAllAmazon(data = {}) {
  return request({
    url: `${VITE_APP_NODEENV !== "production" ? "https://api-test.pacvue.com/meta-api-amazon-dev/" : VITE_APP_META}apply/campaignTagsByProfile`,
    method: "post",
    data: { profileIds: [], ...data },
    cancelToken: true
  })
}

export function getAdGroupTagsAllAmazon(params) {
  return request({
    url: `${VITE_APP_BASEURL}/Tagging/v3/GetAdGroupTag`,
    method: "get",
    params,
    cancelToken: true
  })
}

export function getKeywordTagsAllAmazon(data = {}) {
  return request({
    url: `${VITE_APP_NODEENV !== "production" ? "https://api-test.pacvue.com/meta-api-amazon-dev/" : VITE_APP_META}apply/keywordTags`,
    method: "post",
    data: { toMarket: "US", ...data },
    cancelToken: true
  })
}

export function getAsinTagsAllAmazon(params = {}) {
  return request({
    url: `${VITE_APP_SOV2}/AdObject/GetAllAsinTags`,
    method: "get",
    params: { isCheckEdit: undefined, toMarket: undefined, ...params },
    cancelToken: true
  })
}
