import { $t } from "../i18nEntry"
import * as allCopilotApis from "./api"
import { capitalize } from "lodash-es"

const productLine = window.productline || localStorage.getItem("productline")

export const indexDBname = () =>
  sessionStorage.getItem("useInfo") && sessionStorage.getItem("useInfo") != "undefined" ? `${JSON.parse(sessionStorage.getItem("useInfo")).userId}_Copilot_Conversation` : null

export const getColFormat = (colName) => {
  const numberFields = ["impression", "click", "spend", "sales", "ctr", "acos", "roas", "cpa", "cvr", "cpc", "totaladsales", "orders", "saleunits", "brandedSearch", "adsfrequency", "percent"]
  if (numberFields.includes(colName)) {
    return "Number"
  } else if (colName.indexOf("sov") !== -1 || colName.indexOf("position") !== -1 || colName.indexOf("share") !== -1 || colName.indexOf("rate") !== -1) {
    return "Number"
  } else {
    return "String"
  }
}

export const nameArr = [
  "KeywordText",
  "keywordText",
  "ASIN",
  "Asin",
  "query",
  "targetText",
  "AdgroupName",
  "adgroupName",
  "CampaignName",
  "CampaignType",
  "CampaignTagName",
  "AdGroupTagName",
  "adgroupTagName",
  "AdgroupTagName",
  "AsinTagName",
  "ASINTagName",
  "KeywordTagName",
  "PortfolioName",
  "Brand",
  "VendorGroup",
  "VendorGroupName",
  "ProfileName",
  "SOVGroup"
]

export const metricI18nMap = {
  // Title 相关
  AccountName: $t("DSPkey3254"),
  ProfileName: $t("key597"),
  CampaignName: $t("key174"),
  adgroupName: $t("key62"),
  AdgroupName: $t("key62"),
  PortfolioName: $t("Portfolio Name"),
  CampaignTagName: $t("key1757"),
  AdGroupTagName: $t("key768"),
  KeywordTagName: $t("key768"),
  AsinTagName: $t("ASIN Tag Name"),
  ASINTagName: $t("ASIN Tag Name"),
  targetText: $t("Product"),
  CampaignType: $t("ai103"),
  KeywordText: $t("event108"),
  Brand: $t("key138"),
  VendorGroup: $t("amskey514"),
  // Advertising 相关
  SaleUnits: $t("Sale Units"),
  NewToBrandOrders: $t("NTB-Orders"),
  NewToBrandSales: $t("NTB-Sales"),
  NewToBrandUnitsOrdered: $t("NTB-Units"),
  ViewImpression: $t("amskey1506"),
  BrandedSearch: $t("amskey1513"),
  SearchTermImpressionShare: $t("key2725"),
  Conversion: $t("Orders"),
  Order: $t("Orders"),
  // SOV 相关
  PaidSOV: $t("key551"),
  OrganicSOV: $t("key533"),
  SBSOV: $t("key668"),
  SBProductSOV: $t("key667"),
  SpSOV: $t("key729"),
  SBVSOV: $t("sovkey121"),
  ShareOfShelf: $t("sovkey449"),
  SpTopSOV: $t("sovkey486"),
  SpOtherSOV: $t("sovkey487"),
  Freq: $t("Ads Frequency"),
  Top5TotalSOV: $t("sovkey499"),
  Top10TotalSOV: $t("sovkey500"),
  Top15TotalSOV: $t("sovkey501"),
  Top5OrganicSOV: $t("sovkey502"),
  Top10OrganicSOV: $t("sovkey503"),
  Top15OrganicSOV: $t("sovkey504"),
  TopPageRate: $t("Page 1 Frequency"),
  TopThreeRate: $t("Top 3 Frequency"),
  TopSixRate: $t("Top 6 Frequency"),
  Position1Rate: $t("Position 1 Frequency"),
  Position2Rate: $t("Position 2 Frequency"),
  Position3Rate: $t("Position 3 Frequency"),
  Position4Rate: $t("Position 4 Frequency"),
  Position5Rate: $t("Position 5 Frequency"),
  Position6Rate: $t("Position 6 Frequency"),
  AvgPosition: $t("key130"),
  AvgOrganicPosition: $t("key128"),
  AvgPaidPosition: $t("key129"),
  // PD Center 相关指标
  AdSales: $t("Ad Sales"),
  AdSpend: $t("Ad Spend"),
  OrganicRevenue: $t("Organic Revenue"),
  TACOS: $t("event558"),
  TROAS: $t("key2819"),
  TotalMediaSales: $t("key1920"),
  TotalRevenue: $t("Total Revenue")
}

export const unifyMetrics = (metric) => {
  const otherMap = {
    aov: "sales",
    asp: "sales",
    totalcvr: "cvr",
    searchtermimpressionshare: "impressionshare",
    brandedsearch: "brandedSearch",
    freq: "adsfrequency"
  }
  if (metric.includes("acos")) {
    return "acos"
  } else if (metric.includes("roas")) {
    return "roas"
  } else if (metric.includes("rate")) {
    return "percent"
  } else if (metric.includes("sales") || metric.includes("revenue")) {
    return "sales"
  } else {
    return otherMap[metric]
  }
}

export const displayFieldSpFiltering = ({ jsonParam, fieldName } = {}) => {
  if (productLine === "amazon") {
    if (jsonParam.groupBy === "placement" && fieldName.startsWith("NewToBrand")) {
      return false
    }
  }
  return true
}

export const insightPage = {
  Campaign: "Campaign",
  CampaignAdGroup: "Adgroup",
  Keyword: "Keyword",
  PAT: "PAT",
  CampaignAsin: "ASIN",
  CampaignProfile: "Profile",
  CampaignTagging: "CampaignTag",
  KeywordTagging: "KeywordTag",
  AsinTag: "AsinTag",
  "Product Listing": "ProductCenter-Asin",
  "SOV Brand": "SOV"
}

export const apiDataQueryNavMap = {
  "/api/Profile/ProfilePerformance": {
    destRoutePath: "/Campaign/Profile",
    destFilterKeys: ["profileIds"]
  },
  "/api/Campaign/CampaignPerformance": {
    destRoutePath: "/Campaign/Campaign",
    destFilterKeys: ["campaignIds"]
  },
  "/api/Campaign/CampaignChangeRatioPerformance": {
    destRoutePath: "/Campaign/Campaign",
    destFilterKeys: ["campaignIds"]
  },
  "/api/Portfolio/PortfolioPerformance": {
    destRoutePath: "/Campaign/AmazonPortfolio",
    destFilterKeys: ["portfolioIds"]
  },
  "/api/Adgroup/AdgroupPerformance": {
    destRoutePath: "/Campaign/AdGroup",
    destFilterKeys: ["adgroupIds"]
  },
  "/api/Tagging/CampaignTagPerformance": {
    destRoutePath: "/Campaign/CampaignTagging",
    destFilterKeys: ["taggingIds", "campaignTagIds"],
    allTagApi: allCopilotApis[`getCampaignTagsAll${capitalize(productLine)}`],
    needClientId: true,
    searchFn: (searchId, tagRes) => {
      if (productLine === "amazon") {
        return tagRes.find((tag) => tag.id == searchId)?.children?.map((item) => item.id) ?? []
      }
    }
  },
  "/api/Tagging/AdgroupTagPerformance": {
    destRoutePath: "/Campaign/AdgroupTagging",
    destFilterKeys: ["taggingIds", "adgroupTagIds"],
    allTagApi: allCopilotApis[`getAdGroupTagsAll${capitalize(productLine)}`],
    searchFn: (searchId, tagRes) => {
      if (productLine === "amazon") {
        return tagRes.find((tag) => tag.id == searchId)?.child?.map((item) => item.id) ?? []
      }
    }
  },
  "/api/Tagging/KeywordTagPerformance": {
    destRoutePath: "/Campaign/KeywordTagging",
    destFilterKeys: ["keywordTagIds"]
    // allTagApi: allCopilotApis[`getKeywordTagsAll${capitalize(productLine)}`]
  },
  "/api/Tagging/AsinTagPerformance": {
    destRoutePath: "/Campaign/ASINTagging",
    destFilterKeys: ["taggingIds", "ASINTagIds"],
    allTagApi: allCopilotApis[`getAsinTagsAll${capitalize(productLine)}`],
    searchFn: (searchId, tagRes) => {
      if (productLine === "amazon") {
        return tagRes.find((tag) => tag.tagId == searchId)?.subAsinTags?.map((item) => item.id) ?? []
      }
    }
  },
  "/api/Keyword/KeywordPerformance": {
    textFieldName: "KeywordText",
    destRoutePath: "/Campaign/Keyword",
    destFilterKeys: ["keywordTexts"],
    extraPayload: { keywordOperand: "Equal" }
  },
  "/api/Keyword/KeywordChangeRatioPerformance": {
    textFieldName: "KeywordText",
    destRoutePath: "/Campaign/Keyword",
    destFilterKeys: ["keywordTexts"],
    extraPayload: { keywordOperand: "Equal" }
  },
  "/api/SearchTerm/PATQueryPerformance": {
    textFieldName: "targetText",
    destRoutePath: "/Campaign/Keyword",
    destFilterKeys: ["targetTexts"],
    extraPayload: { targetOperand: "LikeOr", kindType: "PAT" }
  },
  "/api/Asin/AsinPerformance": {
    textFieldName: "ASIN",
    destRoutePath: "/Campaign/Asin",
    destFilterKeys: ["asins"]
  },
  "/api/Asin/AsinChangeRatioPerformance": {
    textFieldName: "ASIN",
    destRoutePath: "/Campaign/Asin",
    destFilterKeys: ["asins"]
  },
  "/api/SearchTerm/KeywordQueryPerformance": {
    textFieldName: "query",
    destRoutePath: "/Campaign/Query",
    destFilterKeys: ["query"],
    extraPayload: { url: "target", type: "PAT", keywordOperand: "Equal" }
  }
}

export const colors = ["#9B88FA", "#FFB268", "#75BDFF", "#FF9B9E", "#8FE4F0", "#6585F3", "#C2F372", "#6ADFA7", "#299AFF", "#DE9DF4", "#28DAC6", "#F0E360", "#F5AAD7", "#97E7D3", "#FFDF6F", "#00CFE8"]

export const insightTypeFieldMap = {
  amazon: {
    Profile: "Profile name",
    Portfolio: "Portfolio name",
    "Campaign Tag": "Campaign Tag name",
    "ASIN Tag": "ASIN Tag name",
    "Keyword Tag": "Keyword Tag name",
    "AdGroup Tag": "AdGroup Tag name",
    Keyword: "Keyword",
    "Product Target": "Product Target",
    Adgroup: "Adgroup name",
    ASIN: "ASIN number",
    SOV: "SOV Group",
    "Product Center - Brand": "Brand name",
    "Product Center - ASIN": "ASIN ID"
  }
}

export const dataQueryGroupByMap = {
  amazon: {
    placement: {
      backForeEndMapper: {
        "other on-amazon": $t("amskey460"),
        "top of search on-amazon": $t("amskey458"),
        "detail page on-amazon": $t("amskey459"),
        "off amazon": $t("amskey2920")
      }
    }
    // campaignType: { cascadedCols: ["CampaignType"] }
  }
}

// Record<String, Map<Array<RegExp>, Array<String>>>
export const PAGE_QUESTIONS_PRESETS = {
  amazon: new Map([
    [{ path: /^\/Home.*/, name: "Home" }, [$t("What does each widget represent in Home module?"), $t("How to customize the widgets in Home module?"), $t("How to share the Home page link?")]],
    [{ path: /^\/ProductCenter\/ProductDashboard.*/, name: "ProductDashboard" }, [$t("What is Product Center used for?")]],
    [
      { path: /^\/ProductCenter\/ProductListing.*/, name: "Product Listing" },
      [$t("What is Product Center used for?"), $t("How to view the performance of a specific ASIN in Product Center?"), $t("How to add ASIN to an adgroup in Product Center?")]
    ],
    [{ path: /^\/Budget\/BudgetDashboard.*/, name: "Budget Manager" }, [$t("amskey3264"), $t("amskey3265"), $t("amskey3266")]],
    [{ path: /^\/Campaign\/CampaignTagging.*/, name: "CampaignTagging" }, [$t("What is a campaign tag used for?"), $t("How to add campaigns to a campaign tag?")]],
    [{ path: /^\/Campaign\/KeywordTagging.*/, name: "KeywordTagging" }, [$t("What is a keyword tag used for?"), $t("How to add keywords to a keyword tag?")]],
    [{ path: /^\/Campaign\/ASINTagging.*/, name: "AsinTag" }, [$t("What is an ASIN tag used for?"), $t("How to add ASIN to an ASIN tag?")]],
    [
      { path: /^\/Campaign\/Campaign.*/, name: "Campaign" },
      [
        $t("How to create sp campaign?"),
        $t("What is Sponsored Products Super Wizard?"),
        $t("How to use automation function to optimize a campaign?"),
        $t("How to view the performance of a specific campaign?")
      ]
    ],
    [{ path: /^\/Campaign\/Keyword.*/, name: "Targeting" }, [$t("How to add keywords to an adgroup?"), $t("How to view the performance of a specific keyword?")]],
    [{ path: /^\/Campaign\/Asin.*/, name: "CampaignAsin" }, [$t("How to add ASIN to an adgroup in ASIN module?"), $t("How to view the performance of a specific ASIN?")]],
    [{ path: /^\/Campaign\/Query.*/, name: "CampaignSearchTerm" }, [$t("How to add queries as keywords to an adgroup?"), $t("How to view the performance of a specific query?")]],
    [{ path: /^\/Report\/Index.*/i, name: "Default Report" }, [$t("DSPkey3503")]],
    [{ path: /^\/Report\/MyReport.*/, name: "My Report" }, [$t("DSPkey3504")]],
    [{ path: /^\/Research\/KeywordsCopy.*/, name: "Keyword Research" }, [$t("What is Keyword Research used for?"), $t("What is the difference between three research types?")]],
    [{ path: /^\/Optimization\/Rule.*/, name: "Rule" }, [$t("amskey3267"), $t("amskey3268"), $t("amskey3269"), $t("amskey3270"), $t("amskey3271")]],
    [{ path: /^\/Optimization\/Dayparting.*/, name: "Dayparting Scheduler" }, [$t("amskey3272"), $t("amskey3273"), $t("amskey3274")]],
    [{ path: /^\/Optimization\/Schedule.*/, name: "Budget Schedule" }, [$t("amskey3275"), $t("amskey3276"), $t("amskey3277")]],
    [{ path: /^\/Optimization\/AutomationAI.*/, name: "Campaign AI" }, [$t("amskey3278"), $t("amskey3279"), $t("amskey3280"), $t("amskey3281"), $t("amskey3282")]],
    [{ path: /^\/Optimization\/ASINAI.*/, name: "Product AI" }, [$t("amskey3283"), $t("amskey3284"), $t("amskey3285"), $t("amskey3286")]],
    [{ path: /^\/AuditCenter.*/, name: "Paid Search Audit" }, [$t("amskey3287"), $t("amskey3288"), $t("amskey3289")]],
    [{ path: /^\/Optimization\/LiveMomentum.*/, name: "War Room" }, [$t("amskey3290"), $t("amskey3291"), $t("amskey3292")]],
    [{ path: /^\/Optimization\/BidExplorer.*/, name: "Bidexplorer" }, [$t("amskey3293"), $t("amskey3294"), $t("amskey3295")]],
    [{ path: /^\/Analysis.*/, name: "Competitive" }, [$t("What is Competitor Analysis used for?"), $t("How to monitor keywords?"), $t("How to view the sov result of a monitored keyword?")]],
    [{ path: /^\/CategoryIntelligence\/SearchTerm.*/, name: "SearchTerm" }, [$t("What is Category Intelligence used for?")]],
    [{ path: /^\/CategoryIntelligence\/Product.*/, name: "Product" }, [$t("What is Category Intelligence used for?")]],
    [{ path: /^\/CategoryIntelligence\/BrandAndSales.*/, name: "BrandAndSales" }, [$t("What is Category Intelligence used for?")]]
    // [{ path: /^\/Permission\/UserManage.*/, name: "User Management" }, [$t("key3048"), $t("key3049")]]
  ]),
  dsp: new Map([
    [{ path: /^\/Dashboard.*/, name: "Dashboard" }, [$t("DSPkey3464"), $t("DSPkey3465")]],
    [{ path: /^\/Budget\/BudgetDashboard.*/, name: "Budget Manager" }, [$t("amskey3264"), $t("amskey3265"), $t("amskey3266")]],
    [{ path: /^\/CampaignManager\/Audience.*/, name: "Audience" }, [$t("DSPkey3484"), $t("DSPkey3485"), $t("DSPkey3486")]],
    [{ path: /^\/CampaignManager\/A.*/, name: "Advertiser" }, [$t("DSPkey3469"), $t("DSPkey3470")]],
    [{ path: /^\/CampaignManager\/Orders.*/, name: "Orders" }, [$t("DSPkey3471"), $t("DSPkey3472"), $t("DSPkey3473"), $t("DSPkey3474")]],
    [{ path: /^\/CampaignManager\/LineItems.*/, name: "LineItems" }, [$t("DSPkey3477"), $t("DSPkey3478")]],
    [{ path: /^\/CampaignManager\/Creative.*/, name: "Creative" }, [$t("DSPkey3479"), $t("DSPkey3480"), $t("DSPkey3481"), $t("DSPkey3482"), $t("DSPkey3483")]],
    [{ path: /^\/CampaignManager\/Product.*/, name: "Product" }, [$t("DSPkey3487"), $t("DSPkey3488"), $t("DSPkey3483"), $t("DSPkey3490")]],
    [{ path: /^\/CampaignManager\/OffAmazon.*/, name: "OffAmazon" }, [$t("DSPkey3491"), $t("DSPkey3492"), $t("DSPkey3493")]],
    [{ path: /^\/Tag\/OrderTag.*/, name: "OrderTag" }, [$t("DSPkey3494"), $t("DSPkey3495"), $t("DSPkey3496")]],
    [{ path: /^\/Tag\/LineTag.*/, name: "LineTag" }, [$t("DSPkey3497"), $t("DSPkey3498"), $t("DSPkey3499")]],
    [{ path: /^\/Tag\/CreativeTag.*/, name: "CreativeTag" }, [$t("DSPkey3500"), $t("DSPkey3501"), $t("DSPkey3502")]],
    [{ path: /^\/AudienceResearch.*/, name: "AudienceLibrary" }, [$t("DSPkey3505"), $t("DSPkey3506"), $t("DSPkey3507")]],
    [{ path: /^\/Research\/CreativeInsight.*/, name: "CreativeInsight" }, [$t("DSPkey3508")]],
    [{ path: /^\/Report\/DefaultReport.*/, name: "DefaultReport" }, [$t("DSPkey3503")]],
    [{ path: /^\/Report\/MyReport.*/, name: "MyReport" }, [$t("DSPkey3504")]],
    [{ path: /^\/Optimization\/Rule.*/, name: "Rule" }, [$t("amskey3267"), $t("amskey3268"), $t("amskey3269"), $t("amskey3270"), $t("amskey3271")]],
    [{ path: /^\/AI.*/, name: "AI" }, [$t("DSPkey3514"), $t("DSPkey3515"), $t("DSPkey3516"), $t("DSPkey3517")]],
    [{ path: /^\/Audit.*/, name: "AuditCenter" }, [$t("DSPkey3518"), $t("DSPkey3519")]]
  ]),
  walmart: new Map([
    [{ path: /^\/Home.*/, name: "Home" }, [$t("What does each widget represent in Home module?"), $t("How to customize the widgets in Home module?"), $t("How to share the Home page link?")]],
    [{ path: /^\/ProductCenter\/ProductDashboard.*/, name: "ProductDashboard" }, [$t("What is Product Center used for?")]],
    [
      { path: /^\/ProductCenter\/ProductListing.*/, name: "Product Listing" },
      [$t("What is Product Center used for?"), $t("How to view the performance of a specific item in Product Center?"), $t("How to add items to an adgroup in Product Center?")]
    ],
    [{ path: /^\/Budget\/BudgetDashboard.*/, name: "Budget Manager" }, [$t("amskey3264"), $t("amskey3265"), $t("amskey3266")]],
    [{ path: /^\/Campaign\/CampaignTagging.*/, name: "CampaignTagging" }, [$t("What is a campaign tag used for?"), $t("How to add campaigns to a campaign tag?")]],
    [{ path: /^\/Campaign\/KeywordTagging.*/, name: "KeywordTagging" }, [$t("What is a keyword tag used for?"), $t("How to add keywords to a keyword tag?")]],
    [{ path: /^\/Campaign\/ItemTagging.*/, name: "ItemTagging" }, [$t("What is an item tag used for?"), $t("How to add items to an item tag?")]],
    [
      { path: /^\/Campaign\/Campaign.*/, name: "Campaign" },
      [$t("How to create sp campaign?"), $t("How to use automation function to optimize a campaign?"), $t("How to view the performance of a specific campaign?")]
    ],
    [{ path: /^\/Campaign\/Keyword.*/, name: "Targeting" }, [$t("How to add keywords to an adgroup?"), $t("How to view the performance of a specific keyword?")]],
    [{ path: /^\/Campaign\/Item.*/, name: "AdvertisingItem" }, [$t("How to add items to an adgroup?"), $t("How to view the performance of a specific item in Item module?")]],
    [{ path: /^\/Campaign\/Query.*/, name: "AdvertisingSearchTerm" }, [$t("How to add queries as keywords to an adgroup?"), $t("How to view the performance of a specific query?")]],
    // [{ path: /^\/Campaign\/BulkOperations.*/, name: "AdvertisingBulkOperations" }, [$t("key3028"), $t("key3029"), $t("key3030")]],
    [{ path: /^\/Report\/index.*/i, name: "Default Report" }, [$t("DSPkey3503")]],
    [{ path: /^\/Report\/MyReport/, name: "My Report" }, [$t("DSPkey3504")]],
    [{ path: /^\/Research\/KeywordsCopy.*/, name: "Keyword Research" }, [$t("What is Keyword Research used for?"), $t("What is the difference between three research types?")]],
    [{ path: /^\/Research\/ImprShare.*/, name: "Impr Share" }, [$t("What is Impr. Share used for?")]],
    [{ path: /^\/Optimization\/QueryAsinGraph.*/, name: "Query-Item Graph" }, [$t("What is Query-Item Graph used for?")]],
    [{ path: /^\/Optimization\/Rule.*/, name: "Rule" }, [$t("amskey3267"), $t("amskey3268"), $t("amskey3269"), $t("amskey3270"), $t("amskey3271")]],
    [{ path: /^\/Optimization\/Dayparting.*/, name: "Dayparting Scheduler" }, [$t("amskey3272"), $t("amskey3273"), $t("amskey3274")]],
    [{ path: /^\/Optimization\/budgetschedule.*/, name: "Budget Scheduler" }, [$t("amskey3275"), $t("amskey3276"), $t("amskey3277")]],
    [{ path: /^\/Optimization\/AutomationAI.*/, name: "Campaign AI" }, [$t("amskey3278"), $t("amskey3279"), $t("amskey3280"), $t("amskey3281"), $t("amskey3282")]],
    [{ path: /^\/Optimization\/ASINAI.*/, name: "Product AI" }, [$t("amskey3283"), $t("amskey3284"), $t("amskey3285"), $t("amskey3286")]],
    [{ path: /^\/AuditCenter.*/, name: "Paid Search Audit" }, [$t("amskey3287"), $t("amskey3288"), $t("amskey3289")]],
    [{ path: /^\/Optimization\/LiveMomentum.*/, name: "War Room" }, [$t("amskey3290"), $t("amskey3291"), $t("amskey3292")]],
    [{ path: /^\/Optimization\/BidExplorer.*/, name: "Bidexplorer" }, [$t("amskey3293"), $t("amskey3294"), $t("amskey3295")]],
    [{ path: /^\/Analysis.*/, name: "Competitive" }, [$t("What is Competitor Analysis used for?"), $t("How to monitor keywords?"), $t("How to view the sov result of a monitored keyword?")]],
    [{ path: /^\/CategoryIntelligence\/SearchTerm.*/, name: "SearchTerm" }, [$t("What is Category Intelligence used for?")]],
    [{ path: /^\/CategoryIntelligence\/Product.*/, name: "Product" }, [$t("What is Category Intelligence used for?")]],
    [{ path: /^\/CategoryIntelligence\/BrandAndSales.*/, name: "BrandAndSales" }, [$t("What is Category Intelligence used for?")]]
    // [{ path: /^\/Account\/MyAccount.*/, name: "My Account" }, [$t("key3045"), $t("key3046"), $t("key3047")]],
    // [{ path: /^\/Permission\/UserManage.*/, name: "User Management" }, [$t("key3048"), $t("key3049")]],
    // [{ path: /\/ShareLink/, name: "Share Link" }, [$t("key3050"), $t("key3051")]]
  ])
}
