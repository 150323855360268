<template>
  <div class="side-menu-container relative h-full w-[62px]" @mouseover="handleSideBarOver" @mouseleave="handleSideBarLeave" v-if="sharePage">
    <div :class="[collapsed ? 'w-[62px]' : 'w-[268px]']" class="side-menu transition-all duration-300 ease-in-out bg-[var(--el-color-bg-menu)] h-full absolute z-[1002]">
      <div class="text-[#FFF] flex items-center px-[12px] py-[10px] relative h-[56px]" :class="{ 'overflow-hidden': !collapsedDur }">
        <div class="w-[38px] relative min-w-[38px]">
          <el-icon v-if="!isWhiteLabel" :size="38" class="left-[0px] flex"><PacvueIconLogo /></el-icon>
          <img v-else class="rounded-[4px] w-[38px] h-[38px]" :src="whiteLabelTinyLogo" @error="handleTinyLogoLoadFail" />
        </div>
        <Transition name="fade">
          <div class="ml-[12px] whitespace-nowrap" v-show="!collapsed">
            <div class="mt-0.5 text-[var(--root-menu-highlight)] font-semibold" :class="{ '!text-[#fff] text-[16px]': isSpWhiteLabel }">{{ title }}</div>
            <div class="text-sm">{{ PLATFORM_NAME_MAPPER[productLine] || productLine }}</div>
          </div>
        </Transition>
      </div>
      <div class="scrollbar h-[calc(100vh-70px)] w-full overflow-hidden hover:overflow-y-auto" ref="menuScrollBarRef">
        <ul class="text-[#FFF] mt-2 space-y-0.5">
          <template v-for="menu in menus" :key="menu.menuId">
            <!-- 收藏夹 -->
            <PacvuePopover
              v-if="menu.type === 'fixed'"
              v-model:visible="favoritesPopoverShow"
              popperClass="favorites-menu-popover"
              :showArrow="false"
              :showAfter="favoritesPopoverShowAfter"
              placement="right"
              @before-enter="handleFavoritesBeforeEnter"
              @before-leave="handleFavoriteBeforeLeave"
            >
              <FavoritesMenus @onFavorItemClick="handleFavoriteItemClick" />
              <template #reference>
                <li class="li-favorite group relative flex items-center pr-3 pl-[21px] h-[46px] hover:opacity-100 hover:bg-white/[0.1] hover:font-bold cursor-pointer">
                  <!-- 下方分割线 -->
                  <div class="absolute bottom-0 left-0 z-[1] w-0 h-[1px] bg-transparent transition-all duration-300" :class="{ '!w-full !bg-[#5c5c5c]': !collapsed }"></div>
                  <el-icon :size="20" id="favorite-entry" color="var(--root-menu-highlight)"><component :is="menu.icon" /></el-icon>
                  <Transition name="fade">
                    <span v-if="!collapsed" class="ml-[6px] whitespace-nowrap text-[14px] opacity-70 group-hover:!opacity-100">{{ menu.i18N }}</span>
                  </Transition>
                  <Transition name="fade">
                    <el-icon v-if="!collapsed" :size="20" color="#ffffff" class="ml-auto ease-linear transition-all duration-400 -rotate-90 opacity-70 group-hover:!opacity-100">
                      <PacvueIconArrowDown1 />
                    </el-icon>
                  </Transition>
                </li>
              </template>
            </PacvuePopover>
            <!-- Level1 无子菜单, 交互: 点击后直接去往路由 -->
            <RouterLink v-else-if="isLeaf(menu) && menu.url.indexOf('https:') == -1" :to="menu.url" @click="handleMenuClick(menu, 1)">
              <li
                :class="[{ active: isActive(menu.menuId), 'opacity-70': !isActive(menu.menuId) }]"
                class="group flex items-center pr-[12px] pl-[21px] h-[46px] hover:opacity-100 hover:bg-white/[0.1] hover:font-bold li-menu"
                @mouseenter="hoveredMenuId = menu.menuId"
              >
                <el-icon :size="20" :color="isActive(menu.menuId) ? 'var(--root-menu-highlight)' : '#ffffff'">
                  <component :is="menu.icon" />
                </el-icon>
                <Transition name="fade">
                  <span v-if="!collapsed" class="ml-[6px] whitespace-nowrap text-[14px]">{{ menu.i18N }}</span>
                </Transition>
                <span v-if="menu.alphaTag && !collapsed" class="ml-[6px] px-[4px] py-[0] font-medium bg-[var(--root-menu-highlight)] text-[var(--root-menu-highlight-text)] rounded-lg text-[10px]">
                  Alpha
                </span>
                <span v-if="menu.betaTag && !collapsed" class="ml-[6px] px-[4px] py-[0] font-medium bg-[var(--root-menu-highlight)] text-[var(--root-menu-highlight-text)] rounded-lg text-[10px]">
                  Beta
                </span>
                <span v-if="menu.newTag && !collapsed" class="ml-[6px] px-[4px] py-[0] font-medium bg-[var(--root-menu-highlight)] text-[var(--root-menu-highlight-text)] rounded-lg text-[10px]">
                  New
                </span>
                <div class="flex items-center h-full ml-auto">
                  <PacvuePopover
                    v-if="!noCollect && (homePage === menu.url || hoveredMenuId === menu.menuId)"
                    trigger="hover"
                    popper-class="favorite-tip"
                    :show-after="500"
                    :teleported="false"
                    placement="top"
                  >
                    <template #reference>
                      <el-icon
                        v-if="!collapsed"
                        :size="20"
                        :color="homePage === menu.url ? 'var(--root-menu-highlight)' : '#dedfe3'"
                        class="group-hover:!inline-flex cursor-pointer"
                        :class="homePage === menu.url ? '!inline-flex' : '!hidden'"
                        @click="(event) => handleHomePageAssign(event, menu)"
                      >
                        <PacvueIconHomeSolid v-if="homePage === menu.url" />
                        <PacvueIconHome v-else />
                      </el-icon>
                    </template>
                    <span v-if="homePage === menu.url">{{ $t("Remove") }}</span>
                    <span v-else>{{ $t("Homepage") }}</span>
                  </PacvuePopover>
                  <PacvuePopover
                    v-if="!noCollect && (judgeInFavorites(menu.menuId) || hoveredMenuId === menu.menuId)"
                    trigger="hover"
                    popper-class="favorite-tip"
                    :show-after="500"
                    :teleported="false"
                    placement="top"
                  >
                    <template #reference>
                      <el-icon
                        v-if="!collapsed"
                        :size="20"
                        :color="judgeInFavorites(menu.menuId) ? 'var(--root-menu-highlight)' : '#dedfe3'"
                        class="group-hover:!inline-flex ml-1 cursor-pointer"
                        :class="judgeInFavorites(menu.menuId) ? '!inline-flex' : '!hidden'"
                        v-collect="{ menu, isFavorite: judgeInFavorites(menu.menuId) }"
                      >
                        <PacvueIconStarfill v-if="judgeInFavorites(menu.menuId)" />
                        <PacvueIconStar v-else />
                      </el-icon>
                    </template>
                    <span v-if="judgeInFavorites(menu.menuId)">{{ $t("Remove") }}</span>
                    <span v-else>{{ $t("Favorite") }}</span>
                  </PacvuePopover>
                </div>
              </li>
            </RouterLink>
            <!-- Level1: 有子菜单, 交互: 点击后展开 Level2, 以下包含父级及展开后的整体区域 -->
            <li v-else class="group level1-menu" @mouseenter="handleLevel1MouseOver(menu, $event)" @mouseleave="handleLevel1MouseOut(menu)" @click="handleMenuClick(menu, 1)">
              <div
                :class="[{ active: isActive(menu.menuId), 'opacity-70': !isActive(menu.menuId) }]"
                class="flex items-center pr-3 pl-[22px] h-[46px] hover:opacity-100 hover:bg-white/[0.1] hover:font-semibold cursor-pointer"
                @click="handleExpand(menu)"
                @mouseenter="hoveredMenuId = menu.menuId"
              >
                <el-icon :size="20" :color="isActive(menu.menuId) ? 'var(--root-menu-highlight)' : '#ffffff'">
                  <component :is="menu.icon" />
                </el-icon>
                <Transition name="fade">
                  <span class="ml-[6px] whitespace-nowrap text-[14px] flex items-center" v-if="!collapsed">
                    {{ menu.i18N }}
                    <!-- 子孙菜单是否具有 new / beta / alpha 属性的标志, 二级展开后消失 -->
                    <span
                      v-if="menu.hasNewMenu"
                      class="menu-badge inline-block w-2 h-2 ml-2 rounded-full bg-[var(--root-menu-highlight)]"
                      :class="{ '!hidden': expandMenu?.menuId === menu.menuId }"
                    ></span>
                  </span>
                </Transition>
                <Transition name="fade">
                  <el-icon v-if="!collapsed" :size="20" color="#FFF" class="ml-auto ease-linear transition-all duration-400" :class="{ 'rotate-180': expandMenu?.menuId === menu.menuId }">
                    <PacvueIconArrowDown1 />
                  </el-icon>
                </Transition>
              </div>
              <div
                :style="{ overflow: (over && hasLevel3(menu)) || (collapsed && over) || overCollapsedLevel2Menu ? 'visible' : 'hidden' }"
                class="overflow-hidden ease-linear duration-300 transition-all"
                :class="[
                  { 'absolute top-0 left-16 z-3000 w-[240px] pl-4 space-y-0.5 group-hover:max-h-screen transition-none': collapsed },
                  expandMenu?.menuId === menu.menuId && !collapsed ? 'h-full' : 'max-h-0'
                ]"
              >
                <ul
                  v-show="menu.menuId === overedLevel1Menu?.menuId"
                  class="group-hover:ease-linear transition duration-300 bg-[var(--el-color-bg-menu)]"
                  :class="{ 'rounded-md': collapsed, 'bg-[var(--el-color-secondbg-menu)]': !collapsed }"
                >
                  <template v-for="submenu in menu.children" :key="submenu.menuId">
                    <RouterLink
                      v-if="(isLeaf(submenu) || collapsed) && submenu.url.indexOf('https') == -1"
                      :to="submenu.url"
                      @click="(event) => handleMenuClick(submenu, 2, { event, propagate: false })"
                    >
                      <!-- Level2: 无子菜单, 交互: 点击直接前往路由 -->
                      <li class="menu-level-2 group ease-linear transition duration-300 li-menu" @mouseenter="handleMouseOver(submenu, $event)" @mouseleave="handleMouseOut(submenu, $event)">
                        <div
                          :class="{ active: isActive(submenu.menuId), 'pl-7': !collapsed }"
                          class="flex items-center pr-[12px] h-[46px] hover:opacity-100 hover:bg-white/[0.1] hover:font-semibold cursor-pointer"
                        >
                          <span class="ml-[20px] mr-[4px] whitespace-nowrap text-[14px]" :class="{ 'opacity-70': !isActive(submenu.menuId) }">
                            {{ submenu.i18N }}
                          </span>
                          <span v-if="submenu.alphaTag" class="ml-[6px] px-[4px] py-[0] font-medium bg-[var(--root-menu-highlight)] text-[var(--root-menu-highlight-text)] rounded-lg text-[10px]">
                            Alpha
                          </span>
                          <span v-if="submenu.betaTag" class="ml-[6px] px-[4px] py-[0] font-medium bg-[var(--root-menu-highlight)] text-[var(--root-menu-highlight-text)] rounded-lg text-[10px]">
                            Beta
                          </span>
                          <span v-if="submenu.newTag" class="ml-[6px] px-[4px] py-[0] font-medium bg-[var(--root-menu-highlight)] text-[var(--root-menu-highlight-text)] rounded-lg text-[10px]">
                            New
                          </span>
                          <el-icon v-if="!isLeaf(submenu)" :size="20" class="ml-auto -rotate-90"><PacvueIconArrowDown1 /></el-icon>
                          <div class="flex items-center h-full ml-auto">
                            <PacvuePopover
                              v-if="!noCollect && isLeaf(submenu) && (homePage === submenu.url || hoveredMenuId === submenu.menuId)"
                              trigger="hover"
                              popper-class="favorite-tip"
                              :show-after="500"
                              :teleported="false"
                              placement="top"
                            >
                              <template #reference>
                                <el-icon
                                  v-if="!collapsed"
                                  :size="20"
                                  :color="homePage === submenu.url ? 'var(--root-menu-highlight)' : '#dedfe3'"
                                  class="group-hover:!inline-flex cursor-pointer"
                                  :class="homePage === submenu.url ? '!inline-flex' : '!hidden'"
                                  @click="(event) => handleHomePageAssign(event, submenu)"
                                >
                                  <PacvueIconHomeSolid v-if="homePage === submenu.url" />
                                  <PacvueIconHome v-else />
                                </el-icon>
                              </template>
                              <span v-if="homePage === submenu.url">{{ $t("Remove") }}</span>
                              <span v-else>{{ $t("Homepage") }}</span>
                            </PacvuePopover>
                            <PacvuePopover
                              v-if="!noCollect && (judgeInFavorites(submenu.menuId) || hoveredMenuId === submenu.menuId)"
                              trigger="hover"
                              popper-class="favorite-tip"
                              :show-after="500"
                              :teleported="false"
                              placement="top"
                            >
                              <template #reference>
                                <el-icon
                                  v-if="!collapsed"
                                  :size="20"
                                  :color="judgeInFavorites(submenu.menuId) ? 'var(--root-menu-highlight)' : '#dedfe3'"
                                  class="group-hover:!inline-flex ml-1 cursor-pointer"
                                  :class="judgeInFavorites(submenu.menuId) ? '!inline-flex' : '!hidden'"
                                  v-collect="{ menu: submenu, isFavorite: judgeInFavorites(submenu.menuId) }"
                                >
                                  <PacvueIconStarfill v-if="judgeInFavorites(submenu.menuId)" />
                                  <PacvueIconStar v-else />
                                </el-icon>
                              </template>
                              <span v-if="judgeInFavorites(submenu.menuId)">{{ $t("Remove") }}</span>
                              <span v-else>{{ $t("Favorite") }}</span>
                            </PacvuePopover>
                          </div>
                        </div>
                      </li>
                    </RouterLink>
                    <!-- 有些菜单链接直接指向站外, 故直接新开标签页打开地址 -->
                    <div v-else-if="(isLeaf(submenu) || collapsed) && submenu.url.indexOf('https') !== -1" class="cursor-pointer hover:opacity-100 hover:bg-white/[0.1] hover:font-semibold">
                      <span @click="jumpToRetailDashboard(submenu.url, { mode: '_blank', isDomainUnified: true })" class="ml-[48px] opacity-70 leading-[46px]">
                        {{ submenu.i18N }}
                      </span>
                    </div>
                    <li v-else class="level2-menu" @mouseenter="handleMouseOver(submenu, $event)" @mouseleave="handleMouseOut(submenu, $event)">
                      <div
                        :class="{ active: isActive(submenu.menuId), 'opacity-70': !isActive(submenu.menuId), 'pl-[26px]': !collapsed }"
                        class="flex items-center pl-[20px] pr-[12px] h-[46px] hover:opacity-100 hover:bg-white/[0.1] hover:font-semibold cursor-pointer"
                      >
                        <span class="ml-[20px]">{{ submenu.i18N }}</span>
                        <el-icon :size="20" class="ml-auto -rotate-90" color="#fff">
                          <PacvueIconArrowDown1 />
                        </el-icon>
                      </div>
                      <!-- 默认隐藏 -->
                      <div class="absolute z-3000 top-[0px] left-[254px] min-w-[240px] space-y-0.5 pl-[20px]" :class="[overedMenu?.menuId == submenu.menuId ? 'block' : 'hidden']">
                        <ul class="group-hover:ease-linear transition duration-300 rounded-md bg-[var(--el-color-bg-menu)]">
                          <li v-for="grandsonMenu in submenu.children" :key="grandsonMenu.menuId" class="li-menu group" @mouseenter="hoveredMenuId = grandsonMenu.menuId">
                            <!-- Level3: 交互: 点击直接去往路由 -->
                            <RouterLink :to="grandsonMenu.url" @click="handleMenuClick(submenu)">
                              <div
                                :class="{ active: isActive(grandsonMenu.menuId), 'opacity-70': !isActive(grandsonMenu.menuId) }"
                                class="flex items-center px-4 h-[42px] text-[14px] hover:bg-white/[0.1] hover:opacity-100"
                              >
                                {{ grandsonMenu.i18N }}
                                <span
                                  v-if="grandsonMenu.alphaTag"
                                  class="ml-[6px] px-[4px] py-[0] font-medium bg-[var(--root-menu-highlight)] text-[var(--root-menu-highlight-text)] rounded-lg text-[10px]"
                                >
                                  Alpha
                                </span>
                                <span
                                  v-if="grandsonMenu.betaTag"
                                  class="ml-[6px] px-[4px] py-[0] font-medium bg-[var(--root-menu-highlight)] text-[var(--root-menu-highlight-text)] rounded-lg text-[10px]"
                                >
                                  Beta
                                </span>
                                <span
                                  v-if="grandsonMenu.newTag"
                                  class="ml-[6px] px-[4px] py-[0] font-medium bg-[var(--root-menu-highlight)] text-[var(--root-menu-highlight-text)] rounded-lg text-[10px]"
                                >
                                  New
                                </span>
                                <div class="flex items-center h-full ml-auto">
                                  <PacvuePopover
                                    v-if="!noCollect && isLeaf(grandsonMenu) && (homePage === grandsonMenu.url || hoveredMenuId === grandsonMenu.menuId)"
                                    trigger="hover"
                                    popper-class="favorite-tip"
                                    :show-after="500"
                                    placement="top"
                                  >
                                    <template #reference>
                                      <el-icon
                                        v-if="!collapsed"
                                        :size="20"
                                        :color="homePage === grandsonMenu.url ? 'var(--root-menu-highlight)' : '#dedfe3'"
                                        class="group-hover:!inline-flex cursor-pointer"
                                        :class="homePage === grandsonMenu.url ? '!inline-flex' : '!hidden'"
                                        @click="(event) => handleHomePageAssign(event, grandsonMenu)"
                                      >
                                        <PacvueIconHomeSolid v-if="homePage === grandsonMenu.url" />
                                        <PacvueIconHome v-else />
                                      </el-icon>
                                    </template>
                                    <span v-if="homePage === grandsonMenu.url">{{ $t("Remove") }}</span>
                                    <span v-else>{{ $t("Homepage") }}</span>
                                  </PacvuePopover>
                                  <PacvuePopover
                                    v-if="!noCollect && (judgeInFavorites(grandsonMenu.menuId) || hoveredMenuId === grandsonMenu.menuId)"
                                    trigger="hover"
                                    popper-class="favorite-tip"
                                    :show-after="500"
                                    placement="top"
                                  >
                                    <template #reference>
                                      <el-icon
                                        v-if="!collapsed"
                                        :size="20"
                                        :color="judgeInFavorites(grandsonMenu.menuId) ? 'var(--root-menu-highlight)' : '#dedfe3'"
                                        class="group-hover:!inline-flex ml-1 cursor-pointer"
                                        :class="judgeInFavorites(grandsonMenu.menuId) ? '!inline-flex' : '!hidden'"
                                        v-collect="{ menu: grandsonMenu, isFavorite: judgeInFavorites(grandsonMenu.menuId) }"
                                      >
                                        <PacvueIconStarfill v-if="judgeInFavorites(grandsonMenu.menuId)" />
                                        <PacvueIconStar v-else />
                                      </el-icon>
                                    </template>
                                    <span v-if="judgeInFavorites(grandsonMenu.menuId)">{{ $t("Remove") }}</span>
                                    <span v-else>{{ $t("Favorite") }}</span>
                                  </PacvuePopover>
                                </div>
                              </div>
                            </RouterLink>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </template>
                </ul>
              </div>
            </li>
          </template>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch, onMounted, onUnmounted } from "vue"
import { isLeaf, findMenuPathById, path2root } from "../../../utils/menu-tree.js"
import useFrameStore from "../../frameStore"
import { get as lodashGet, debounce } from "lodash-es"
import { useRoute, useRouter } from "vue-router"
import { useUserStore } from "@/store"
import { storeToRefs } from "pinia"
import { jumpToRetailDashboard } from "../../common"
import config from "@~/config.js"
import { isWhiteLabel, isSpWhiteLabel, spWhiteLabelConfig } from "../../../constants/env.js"
import { PLATFORM_NAME_MAPPER } from "../../../constants/platform.js"
import FavoritesMenus from "../../components/FavoritesMenus.vue"
import { useSettleHomepage } from "../../../hooks/menu-pref.js"

const noCollect = ref(config.noCollect)
const appStore = useFrameStore()
const userStore = useUserStore()
const route = useRoute()
const router = useRouter()

// 菜单两种状态 菜单最多只有 L3
// 压缩与展开 L2 单击直接展开，L3 在 L2 Hover 后右侧展开
// 展开状态
const sharePage = computed(() => {
  let isSharePage = userStore.sharePage
  if (route.query.shareId) {
    isSharePage = false
  }
  return isSharePage === "false" ? false : isSharePage
})

const mouseInSideBar = ref(false)

const productLine = window.productline || localStorage.getItem("productline")
const { sideBarIsExpend, menu: menus } = storeToRefs(appStore)
const { userSettings } = storeToRefs(userStore)
const over = ref(false)
const menuScrollBarRef = ref(null)
const clickedMenu = ref(null)
const overedMenu = ref(null)
const overCollapsedLevel2Menu = ref(false)
const overedLevel1Menu = ref(null)
const favoritesPopoverShow = ref(false)
const favoritesPopoverShowAfter = ref(0)
const hoveredMenuId = ref(-1)
const { homePage, handleHomePageAssign } = useSettleHomepage()
//二级菜单的展开
const expandMenu = ref(null)

// 有收藏夹功能, 顶部追加收藏夹菜单项
if (!config.noCollect) {
  menus.value.unshift({ icon: "PacvueIconStarfill", i18N: $t("Favorites"), type: "fixed", chidren: [] })
}

// 当前激活的菜单
const handleMouseOver = (menu, e) => {
  hoveredMenuId.value = menu.menuId
  if (!collapsed.value) {
    overedMenu.value = menu
    over.value = true
    const liElement = e.target.closest("li.level2-menu")
    const level3Menu = liElement?.querySelector(".absolute")
    const { top } = liElement?.getBoundingClientRect() ?? {}
    if (level3Menu && top) {
      level3Menu.style.top = `${top}px`
    }
  } else {
    const liElement = e.target.closest("li.menu-level-2")
    const { top: top1 } = liElement.offsetParent.getBoundingClientRect()
    overCollapsedLevel2Menu.value = true
    overedMenu.value = menu
    const level3Menu = liElement?.querySelector(".absolute")
    const { top } = liElement.getBoundingClientRect()
    if (level3Menu) {
      level3Menu.style.top = `${top - top1}px`
    }
    // menu-level-2
  }
}

const handleMouseOut = (e, event) => {
  if (menuScrollBarRef.value == event.relatedTarget) {
    return
  }
  if (!collapsed.value) {
    over.value = false
    overedMenu.value = null
  } else {
    overCollapsedLevel2Menu.value = false
    overedMenu.value = null
  }
  // const level3Menu = e.target?.parentElement?.querySelector('.absolute')
  // if(level3Menu) {
  //   level3Menu.style = `top: 0`
  // }
}

// 是否有三级菜单
const hasLevel3 = (menu) => {
  const children = lodashGet(menu, "children", [])
  const child = children.find((child) => !isLeaf(child))
  return !!child
}

const isRoot = (item) => {
  // parentId 为 0 或者不存在为根节点
  return !item.parentId
}

const findMenus = (menus, menuId) => {
  const menu = menus.find((menu) => menu.menuId === menuId)
  if (!menu) {
    return []
  }
  if (isRoot(menu)) {
    return [menu]
  }
  const parentMenus = findMenus(menus, menu.parentId)
  return [...parentMenus, menu]
}

const activeMenus = computed(() => {
  const allRegisteredRoutes = router.getRoutes()
  let menuMap = {}
  for (let key in appStore.menuMap) {
    menuMap[key.split("/#")[0]] = appStore.menuMap[key]
  }
  const menus = appStore.menu
  let menu = menuMap[route.path]
  for (var i in menuMap) {
    if (route.path.lastIndexOf("/Event/AdvertisingEvent") != -1) {
      menu = menuMap["/Event/AdvertisingEvent"]
    } else if ((route.path + "/").lastIndexOf(i + "/") !== -1) {
      // 这里的代码需要留意
      menu = menuMap[i]
      break
    }
  }
  if (route.path.lastIndexOf("/Budget/") != -1) {
    menu = menuMap["/Budget/BudgetDashboard"]
  } else if (route.path.lastIndexOf("/BudgetPlanning/") != -1) {
    menu = menuMap["/BudgetPlanning/BudgetMonitoring"]
  } else if (!menu && route.path.lastIndexOf("/Report/") != -1) {
    menu = menuMap["/Report/Index"]
  } else if (route.path.lastIndexOf("/createMyReport") != -1 || route.path.lastIndexOf("/viewHistoryReport") != -1) {
    menu = menuMap["/Report/MyReport"]
  } else if (route.path.lastIndexOf("/ManageKeywordTag") != -1 || route.path.lastIndexOf("/Analysis/SOV") != -1) {
    menu = menuMap["/Analysis/Competitive"]
  } else if (route.path.lastIndexOf("/ProductCenter/ProductNonVC") != -1) {
    menu = menuMap["/Product/ProductsDashBoard"]
  } else if (route.path.lastIndexOf("/Optimization/BidExplorer#/") != -1) {
    menu = menuMap["/Optimization/BidExplorer#/"]
  } else if (route.path.lastIndexOf("/Event/TaskOtherDetail") != -1) {
    menu = menuMap["/Event/TaskCenter"]
  } else if (route.path.lastIndexOf("/Optimization/ASINAI/#/AI/index") != -1) {
    menu = menuMap["/Optimization/ASINAI"]
  } else if (route.path.lastIndexOf("/DSP/DSP") != -1) {
    if (route.fullPath.indexOf("AB/ABDetails") !== -1) {
      menu = menuMap["/DSP/DSP?u=/ab"]
    } else {
      menu = menuMap[route.fullPath]
    }
  } else if (route.meta?.parent) {
    const parentURL = path2root(allRegisteredRoutes, route.name)[0]?.path
    menu = menuMap[parentURL]
  }
  if (menu) {
    return findMenuPathById(menus, menu.menuId)
  } else {
    return []
  }
})

const collapsed = computed(() => {
  return !sideBarIsExpend.value
})

watch(
  [activeMenus, collapsed],
  ([menus, state]) => {
    if (menus.length > 0 && !state) {
      expandMenu.value = menus[0]
      overedLevel1Menu.value = menus[0]
    }
  },
  { immediate: true }
)

const isActive = (menuId) => {
  return activeMenus.value.map((menu) => menu.menuId).includes(menuId)
}

const handleMenuClick = (menu, level, { event, propagate = true } = {}) => {
  if (menu.menuName == "Event") {
    localStorage.setItem("AdvertisingEventTab", "All")
  }
  if (!propagate) {
    event.stopPropagation()
  }
  if (level == 1) {
    if (overedLevel1Menu.value?.menuId === menu.menuId) {
      overedLevel1Menu.value = null
      expandMenu.value = null
    } else {
      overedLevel1Menu.value = menu
      expandMenu.value = menu
    }
  }
  clickedMenu.value = menu
}

const handleSideBarOver = function () {
  mouseInSideBar.value = true
  appStore.setSideBarIsExpend(true)
}

const handleSideBarLeave = function () {
  mouseInSideBar.value = false
  if (favoritesPopoverShow.value) {
    return
  }
  appStore.setSideBarIsExpend(false)
  over.value = false
  overedMenu.value = null
}

const handleExpand = (menu) => {
  if (expandMenu.value === menu) {
    expandMenu.value = null
  } else {
    expandMenu.value = menu
  }
}

const handleLevel1MouseOver = (menu, e) => {
  if (collapsed.value) {
    overedLevel1Menu.value = menu
    const liElement = e.target.closest("li.level1-menu")
    const level2Menu = liElement.querySelector(".absolute")
    const { top } = liElement.getBoundingClientRect()
    if (level2Menu) {
      // const {top, bottom} = level2Menu.getBoundingClientRect()
      // const viewportHeight = window.innerHeight
      // if(bottom > viewportHeight) {
      //   level2Menu.style = `bottom: ${viewportHeight}px`
      // } else {
      //   level2Menu.style = `top: ${topOffset}px`
      // }
      level2Menu.style.top = `${top}px`
    }
  }
}

const handleLevel1MouseOut = (menu, event) => {
  if (collapsed.value) {
    overedLevel1Menu.value = null
  }
  // const level2Menu = event.target?.parentElement?.querySelector('.absolute')
  // const topOffset = event.target?.offsetTop - menuScrollBarRef.value.scrollTop
  // if (level2Menu) {
  //   const {top, bottom} = level2Menu.getBoundingClientRect()
  //   const viewportHeight = window.innerHeight
  //   if(bottom > viewportHeight) {
  //     level2Menu.style = `bottom: auto`
  //   } else{
  //     level2Menu.style = `top: ${0}px`
  //   }
  // }
}
const title = computed(() => {
  if (document.getElementById("fav-title")?.content?.lastIndexOf("Pacvue") != -1) {
    return document.getElementById("fav-title").content.indexOf("Commerce") != -1 ? "PACVUE COMMERCE" : "PACVUE ADS"
  } else {
    if (isSpWhiteLabel && spWhiteLabelConfig.isPoweredByPacvueTitle) {
      return "Powered by Pacvue"
    } else {
      return document.getElementById("fav-title")?.content
    }
  }
})

const collapsedDur = ref(true)
const sideBarExpanding = ref(false)
watch(
  () => sideBarIsExpend.value,
  (v) => {
    if (!v) {
      // 收起
      collapsedDur.value = false
      setTimeout(() => {
        hoveredMenuId.value = -1
      }, 300)
    } else {
      // 展开
      sideBarExpanding.value = true
      setTimeout(() => {
        collapsedDur.value = true
        sideBarExpanding.value = false
      }, 300)
    }
  },
  {
    immediate: true
  }
)

const handleFavoritesBeforeEnter = () => {
  if (sideBarExpanding.value) {
    favoritesPopoverShowAfter.value = 300
  } else {
    favoritesPopoverShowAfter.value = 0
  }
}

const handleFavoriteBeforeLeave = (e) => {
  if (!mouseInSideBar.value) {
    handleSideBarLeave()
  }
}

const judgeInFavorites = (menuId) => {
  return userSettings.value?.userFavourite?.length > 0 && userSettings.value.userFavourite.includes(menuId)
}

const handleFavoriteItemClick = () => {
  favoritesPopoverShow.value = false
  handleSideBarLeave()
}

const whiteLabelTinyLogo = ref("https://cdn-pacvue-public-doc.pacvue.com/oss/pacvue-cdn/vue3/" + (isSpWhiteLabel ? spWhiteLabelConfig.tinyLogoPNG : window.location.hostname) + "title.png")
function handleTinyLogoLoadFail() {
  whiteLabelTinyLogo.value = "https://cdn-pacvue-public-doc.pacvue.com/oss/pacvue-cdn/vue3/defaultlogo.svg"
}

onMounted(() => {})

onUnmounted(() => {})
</script>

<style lang="scss" scoped>
/* 自定义垂直滚动条 */
.scrollbar {
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.3);
    border-radius: 10px;
  }
}
/* 搭配 Transition 组件过渡 */
.fade-enter-active {
  transition: opacity 0.4s ease-in;
}
.fade-leave-active {
  transition: opacity 0.4s ease-in;
}
.fade-enter-from {
  opacity: 0;
}
.fade-leave-to {
  opacity: 0;
}
/* 侧边栏 */
.side-menu {
  .active {
    color: var(--root-menu-highlight);
    font-weight: 600;
  }
  :deep(a) {
    color: inherit;
  }
}
</style>

<style lang="scss">
.favorite-tip {
  min-width: inherit !important;
}
.favorites-menu-popover {
  top: 56px !important;
  left: 268px !important;
  min-width: 240px !important;
  height: fit-content !important;
  padding: 0 !important;
  border-radius: 6px !important;
  box-shadow: 0 4px 14px 4px rgba(0, 0, 0, 0.06) !important;
}
</style>
