import localForage from "localforage"
import { useUserStore } from "@/store"
import { indexDBname, insightTypeFieldMap } from "./config"
import { SENDER, CONVERSATION_TYPE } from "./constants"
import { getCopilotQuestionsPresetsByRoute } from "../../utils/copilot"

const forageInstances = Object.values(CONVERSATION_TYPE).reduce((prev, curr) => {
  return {
    ...prev,
    [curr]: localForage.createInstance({
      driver: localForage.INDEXEDDB,
      name: "PacvueCopilot",
      storeName: curr,
      description: "Conversation data of Copilot."
    })
  }
}, {})

let lastRoutePath = ""

const initChat = async ({ isClear = false, conversationType = CONVERSATION_TYPE.FEATURE_INSTRUCTION, routePath = "" } = {}) => {
  const productLine = window.productLine || localStorage.getItem("productline")
  const userStore = useUserStore()
  const storageKey = `${userStore.user?.userId ?? ""}_CopilotLastRoutePath`
  const lastRoutePath = sessionStorage.getItem(storageKey) || ""
  let currTimestamp = new Date().getTime()
  let moduleName = ""
  let presets = []
  if (routePath) {
    presets = getCopilotQuestionsPresetsByRoute()
    moduleName = window.menuUrlItemMap[routePath]?.i18N || ""
  }
  if (lastRoutePath !== routePath) {
    isClear = true
  }
  sessionStorage.setItem(storageKey, routePath)
  const initData = [
    {
      type: "time",
      message: currTimestamp,
      key: new Date() + "time"
    },
    {
      type: "message",
      status: "success",
      allData: null,
      message: "",
      sender: SENDER.MODEL,
      key: new Date() + "message"
    }
  ]
  switch (conversationType) {
    case CONVERSATION_TYPE.DATA_QUERY:
      initData[1].message = `
        <div class='mb-2'>${$t("amskey3301")}</div>
        <div class="color-primary action-chat preset-wrapper" style="white-space: initial; align-items: initial;" attr-data='sendCopy'>
          <span class='preset-ind'></span>
          <span class='action-chat preset-example' attr-data='sendCopy'>${$t("Show me top 10 campaigns of last month ordered by sales.")}</span>
        </div>
        <div class="color-primary action-chat preset-wrapper" style="white-space: initial; align-items: initial;" attr-data='sendCopy'>
          <span class='preset-ind'></span>
          <span class='action-chat preset-example' attr-data='sendCopy'>${$t("Show me Campaign performance group by campaign type of the last 14 days.")}</span>
        </div>
        <div class="color-primary action-chat preset-wrapper" style="white-space: initial; align-items: initial;" attr-data='sendCopy'>
          <span class='preset-ind'></span>
          <span class='action-chat preset-example' attr-data='sendCopy'>${$t("Show me top 10 campaigns group by placement.")}</span>
        </div>
      `
      initData.push({
        type: "tips",
        message: $t("amskey3305"),
        key: new Date() + "tips"
      })
      break
    case CONVERSATION_TYPE.INSIGHT:
      // v2.2 不上, 暂时注掉
      // initData[1].message = `
      //   <div class='mb-2'>${$t("amskey3304")}</div>
      //   ${Object.entries(insightTypeFieldMap[productLine])
      //     .map(
      //       ([type, field]) => `
      //       <div class="color-primary action-chat preset-wrapper" style="white-space: initial; align-items: initial;" attr-data='sendCopy'>
      //         <span class='preset-ind'></span>
      //         <span class='action-chat preset-example' attr-data='sendInsightType' data-insight-field='${field}'>${$t(type)}</span>
      //       </div>
      //   `
      //     )
      //     .join("")}
      // `
      initData[1].message = `<div>${$t("amskey3304")}</div>`
      break
    case CONVERSATION_TYPE.FEATURE_INSTRUCTION:
      initData[1].message = `<div><span>${$t("amskey3302")}</span></div>`
      break
  }
  const forageInstance = forageInstances[conversationType]
  const data = await forageInstance.getItem(indexDBname())
  if (data && !isClear) {
    if (data[data.length - 1].type === "Thinking") data.pop()
    // 根据当前路由，修改 Feature Suggestion 提问预设
    if (conversationType === CONVERSATION_TYPE.FEATURE_INSTRUCTION && presets.length > 0) {
      data[1].message = `<div class='mb-2'>${$t("amskey3303", [moduleName])}</div>`
      presets.forEach(
        (preset) =>
          (data[1].message += `
          <div class="color-primary action-chat preset-wrapper" style="white-space: initial; align-items: initial;" attr-data='sendCopy'>
            <span class='preset-ind'></span>
            <span class='action-chat preset-example' attr-data='sendCopy'>${preset}</span>
          </div>
        `)
      )
    } else if (conversationType === CONVERSATION_TYPE.DATA_QUERY) {
      // data query 强制加上侵权保护声明文本
      if (data[2].type !== "tips") {
        data.splice(2, 0, {
          type: "tips",
          message: $t("amskey3305"),
          key: new Date() + "tips"
        })
      }
    }
    return data
  } else {
    // 根据当前路由，修改 Feature Suggestion 提问预设
    if (conversationType === CONVERSATION_TYPE.FEATURE_INSTRUCTION && presets.length > 0) {
      initData[1].message = `<div class='mb-2'>${$t("amskey3303", [moduleName])}</div>`
      presets.forEach(
        (preset) =>
          (initData[1].message += `
          <div class="color-primary action-chat preset-wrapper" style="white-space: initial; align-items: initial;" attr-data='sendCopy'>
            <span class='preset-ind'></span>
            <span class='action-chat preset-example' attr-data='sendCopy'>${preset}</span>
          </div>
        `)
      )
    }
    await forageInstance.setItem(indexDBname(), initData)
    return initData
  }
}

const updatePresets = async ({ conversationType, routePath }) => {
  const presets = getCopilotQuestionsPresetsByRoute()
  const moduleName = window.menuUrlItemMap[routePath]?.i18N || ""
  if (conversationType === CONVERSATION_TYPE.FEATURE_INSTRUCTION && presets.length > 0) {
    const forageInstance = forageInstances[CONVERSATION_TYPE.FEATURE_INSTRUCTION]
    const data = await forageInstance.getItem(indexDBname())
    data[1].message = `<div class='mb-2'>${$t("amskey3303", [moduleName])}</div>`
    presets.forEach(
      (preset) =>
        (data[1].message += `
          <div class="color-primary action-chat preset-wrapper" style="white-space: initial; align-items: initial;" attr-data='sendCopy'>
            <span class='preset-ind'></span>
            <span class='action-chat preset-example' attr-data='sendCopy'>${preset}</span>
          </div>
        `)
    )
    await forageInstance.setItem(indexDBname(), data)
    return data
  }
}

export { forageInstances, initChat, updatePresets }
